import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import PaginationButtons from "src/components/pagination";
import Scrollbar from "src/components/Scrollbar";
import useLocales from "src/hooks/useLocales";
import { fDate } from "src/utils/formatNumber.js";
import { capitalCase } from "change-case";
import DataHandlerTable from "src/components/data-handler/table";
import Loop from "src/components/loop";

const DataList = ({ state, rowStart, currency, ...rest }) => {
  const { translate } = useLocales();
  const { data, ...dataProps } = state;
  const headers = [
    "myEwallet.no",
    "myEwallet.userName",
    "myEwallet.fromUser",
    "myEwallet.amountType",
    "myEwallet.amount",
    "notes",
    "myEwallet.date",
  ];
  return (
    <>
      <Card sx={{ mt: 2 }}>
        <Scrollbar>
          <DataHandlerTable headers={headers} dataProps={dataProps}>
            <Loop
              list={data}
              render={(item, i) => {
                return (
                  <>
                    <TableRow>
                      <TableCell>{i + rowStart}</TableCell>
                      <TableCell>{item.user.username}</TableCell>
                      <TableCell>{item.from_user.username}</TableCell>
                      <TableCell>{capitalCase(item.payment_type)}</TableCell>
                      {item.total_amount < 0 ? (
                        <TableCell sx={{ color: "red" }}>
                          {item.total_amount}
                        </TableCell>
                      ) : (
                        <TableCell>{item.total_amount}</TableCell>
                      )}
                      <TableCell>{item.note}</TableCell>
                      <TableCell>{fDate(item.created_at)}</TableCell>
                    </TableRow>
                  </>
                );
              }}
            />
          </DataHandlerTable>
        </Scrollbar>
      </Card>
      <PaginationButtons {...rest} />
    </>
  );
};
export default DataList;
