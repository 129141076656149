export const countries = [
  {
    label: "Afghanistan",
    code: "AFG",
    "country-code": "004",
    currency: "AFN",
  },
  {
    label: "Åland Islands",
    code: "ALA",
    "country-code": "248",
    currency: "EUR",
  },
  {
    label: "Albania",
    code: "ALB",
    "country-code": "008",
    currency: "ALL",
  },
  {
    label: "Algeria",
    code: "DZA",
    "country-code": "012",
    currency: "DZD",
  },
  {
    label: "American Samoa",
    code: "ASM",
    "country-code": "016",
    currency: "USD",
  },
  {
    label: "Andorra",
    code: "AND",
    "country-code": "020",
    currency: "EUR",
  },
  {
    label: "Angola",
    code: "AGO",
    "country-code": "024",
    currency: "AOA",
  },
  {
    label: "Anguilla",
    code: "AIA",
    "country-code": "660",
    currency: "XCD",
  },
  {
    label: "Antarctica",
    code: "ATA",
    "country-code": "010",
    currency: null,
  }, // Antarctica doesn't have a specific currency
  {
    label: "Antigua and Barbuda",
    code: "ATG",
    "country-code": "028",
    currency: "XCD",
  },
  {
    label: "Argentina",
    code: "ARG",
    "country-code": "032",
    currency: "ARS",
  },
  {
    label: "Armenia",
    code: "ARM",
    "country-code": "051",
    currency: "AMD",
  },
  {
    label: "Aruba",
    code: "ABW",
    "country-code": "533",
    currency: "AWG",
  },
  {
    label: "Australia",
    code: "AUS",
    "country-code": "036",
    currency: "AUD",
  },
  {
    label: "Austria",
    code: "AUT",
    "country-code": "040",
    currency: "EUR",
  },
  {
    label: "Azerbaijan",
    code: "AZE",
    "country-code": "031",
    currency: "AZN",
  },
  {
    label: "Bahamas",
    code: "BHS",
    "country-code": "044",
    currency: "BSD",
  },
  {
    label: "Bahrain",
    code: "BHR",
    "country-code": "048",
    currency: "BHD",
  },
  {
    label: "Bangladesh",
    code: "BGD",
    "country-code": "050",
    currency: "BDT",
  },
  {
    label: "Barbados",
    code: "BRB",
    "country-code": "052",
    currency: "BBD",
  },
  {
    label: "Belarus",
    code: "BLR",
    "country-code": "112",
    currency: "BYN",
  },
  {
    label: "Belgium",
    code: "BEL",
    "country-code": "056",
    currency: "EUR",
  },
  {
    label: "Belize",
    code: "BLZ",
    "country-code": "084",
    currency: "BZD",
  },
  {
    label: "Benin",
    code: "BEN",
    "country-code": "204",
    currency: "XOF",
  },
  {
    label: "Bermuda",
    code: "BMU",
    "country-code": "060",
    currency: "BMD",
  },
  {
    label: "Bhutan",
    code: "BTN",
    "country-code": "064",
    currency: "BTN",
  },
  {
    label: "Bolivia (Plurinational State of)",
    code: "BOL",
    "country-code": "068",
    currency: "BOB",
  },
  {
    label: "Bonaire, Sint Eustatius and Saba",
    code: "BES",
    "country-code": "535",
    currency: "USD",
  },
  {
    label: "Bosnia and Herzegovina",
    code: "BIH",
    "country-code": "070",
    currency: "BAM",
  },
  {
    label: "Botswana",
    code: "BWA",
    "country-code": "072",
    currency: "BWP",
  },
  {
    label: "Bouvet Island",
    code: "BVT",
    "country-code": "074",
    currency: null,
  },
  {
    label: "Brazil",
    code: "BRA",
    "country-code": "076",
    currency: "BRL",
  },
  {
    label: "British Indian Ocean Territory",
    code: "IOT",
    "country-code": "086",
    currency: "USD",
  },
  {
    label: "Brunei Darussalam",
    code: "BRN",
    "country-code": "096",
    currency: "BND",
  },
  {
    label: "Bulgaria",
    code: "BGR",
    "country-code": "100",
    currency: "BGN",
  },
  {
    label: "Burkina Faso",
    code: "BFA",
    "country-code": "854",
    currency: "XOF",
  },
  {
    label: "Burundi",
    code: "BDI",
    "country-code": "108",
    currency: "BIF",
  },
  {
    label: "Cabo Verde",
    code: "CPV",
    "country-code": "132",
    currency: "CVE",
  },
  {
    label: "Cambodia",
    code: "KHM",
    "country-code": "116",
    currency: "KHR",
  },
  {
    label: "Cameroon",
    code: "CMR",
    "country-code": "120",
    currency: "XAF",
  },
  {
    label: "Canada",
    code: "CAN",
    "country-code": "124",
    currency: "CAD",
  },
  {
    label: "Cayman Islands",
    code: "CYM",
    "country-code": "136",
    currency: "KYD",
  },
  {
    label: "Central African Republic",
    code: "CAF",
    "country-code": "140",
    currency: "XAF",
  },
  { label: "Chad", code: "TCD", "country-code": "148", currency: "XAF" },
  {
    label: "Chile",
    code: "CHL",
    "country-code": "152",
    currency: "CLP",
  },
  {
    label: "China",
    code: "CHN",
    "country-code": "156",
    currency: "CNY",
  },
  {
    label: "Christmas Island",
    code: "CXR",
    "country-code": "162",
    currency: "AUD",
  },
  {
    label: "Cocos (Keeling) Islands",
    code: "CCK",
    "country-code": "166",
    currency: "AUD",
  },
  {
    label: "Colombia",
    code: "COL",
    "country-code": "170",
    currency: "COP",
  },
  {
    label: "Comoros",
    code: "COM",
    "country-code": "174",
    currency: "KMF",
  },
  {
    label: "Congo",
    code: "COG",
    "country-code": "178",
    currency: "XAF",
  },
  {
    label: "Congo, Democratic Republic of the",
    code: "COD",
    "country-code": "180",
    currency: "CDF",
  },
  {
    label: "Cook Islands",
    code: "COK",
    "country-code": "184",
    currency: "NZD",
  },
  {
    label: "Costa Rica",
    code: "CRI",
    "country-code": "188",
    currency: "CRC",
  },
  {
    label: "Côte d'Ivoire",
    code: "CIV",
    "country-code": "384",
    currency: "XOF",
  },
  {
    label: "Croatia",
    code: "HRV",
    "country-code": "191",
    currency: "HRK",
  },
  { label: "Cuba", code: "CUB", "country-code": "192", currency: "CUP" },
  {
    label: "Curaçao",
    code: "CUW",
    "country-code": "531",
    currency: "ANG",
  },
  {
    label: "Cyprus",
    code: "CYP",
    "country-code": "196",
    currency: "EUR",
  },
  {
    label: "Czechia",
    code: "CZE",
    "country-code": "203",
    currency: "CZK",
  },
  {
    label: "Denmark",
    code: "DNK",
    "country-code": "208",
    currency: "DKK",
  },
  {
    label: "Djibouti",
    code: "DJI",
    "country-code": "262",
    currency: "DJF",
  },
  {
    label: "Dominica",
    code: "DMA",
    "country-code": "212",
    currency: "XCD",
  },
  {
    label: "Dominican Republic",
    code: "DOM",
    "country-code": "214",
    currency: "DOP",
  },
  {
    label: "Ecuador",
    code: "ECU",
    "country-code": "218",
    currency: "USD",
  },
  {
    label: "Egypt",
    code: "EGY",
    "country-code": "818",
    currency: "EGP",
  },
  {
    label: "El Salvador",
    code: "SLV",
    "country-code": "222",
    currency: "USD",
  },
  {
    label: "Equatorial Guinea",
    code: "GNQ",
    "country-code": "226",
    currency: "XAF",
  },
  {
    label: "Eritrea",
    code: "ERI",
    "country-code": "232",
    currency: "ERN",
  },
  {
    label: "Estonia",
    code: "EST",
    "country-code": "233",
    currency: "EUR",
  },
  {
    label: "Eswatini",
    code: "SWZ",
    "country-code": "748",
    currency: "SZL",
  },
  {
    label: "Ethiopia",
    code: "ETH",
    "country-code": "231",
    currency: "ETB",
  },
  {
    label: "Falkland Islands (Malvinas)",
    code: "FLK",
    "country-code": "238",
    currency: "FKP",
  },
  {
    label: "Faroe Islands",
    code: "FRO",
    "country-code": "234",
    currency: "DKK",
  },
  { label: "Fiji", code: "FJI", "country-code": "242", currency: "FJD" },
  {
    label: "Finland",
    code: "FIN",
    "country-code": "246",
    currency: "EUR",
  },
  {
    label: "France",
    code: "FRA",
    "country-code": "250",
    currency: "EUR",
  },
  {
    label: "French Guiana",
    code: "GUF",
    "country-code": "254",
    currency: "EUR",
  },
  {
    label: "French Polynesia",
    code: "PYF",
    "country-code": "258",
    currency: "XPF",
  },
  {
    label: "French Southern Territories",
    code: "ATF",
    "country-code": "260",
    currency: null,
  }, // French Southern Territories doesn't have a specific currency
  {
    label: "Gabon",
    code: "GAB",
    "country-code": "266",
    currency: "XAF",
  },
  {
    label: "Gambia",
    code: "GMB",
    "country-code": "270",
    currency: "GMD",
  },
  {
    label: "Georgia",
    code: "GEO",
    "country-code": "268",
    currency: "GEL",
  },
  {
    label: "Germany",
    code: "DEU",
    "country-code": "276",
    currency: "EUR",
  },
  {
    label: "Ghana",
    code: "GHA",
    "country-code": "288",
    currency: "GHS",
  },
  {
    label: "Gibraltar",
    code: "GIB",
    "country-code": "292",
    currency: "GIP",
  },
  {
    label: "Greece",
    code: "GRC",
    "country-code": "300",
    currency: "EUR",
  },
  {
    label: "Greenland",
    code: "GRL",
    "country-code": "304",
    currency: "DKK",
  },
  {
    label: "Grenada",
    code: "GRD",
    "country-code": "308",
    currency: "XCD",
  },
  {
    label: "Guadeloupe",
    code: "GLP",
    "country-code": "312",
    currency: "EUR",
  },
  { label: "Guam", code: "GUM", "country-code": "316", currency: "USD" },
  {
    label: "Guatemala",
    code: "GTM",
    "country-code": "320",
    currency: "GTQ",
  },
  {
    label: "Guernsey",
    code: "GGY",
    "country-code": "831",
    currency: "GBP",
  },
  {
    label: "Guinea",
    code: "GIN",
    "country-code": "324",
    currency: "GNF",
  },
  {
    label: "Guinea-Bissau",
    code: "GNB",
    "country-code": "624",
    currency: "XOF",
  },
  {
    label: "Guyana",
    code: "GUY",
    "country-code": "328",
    currency: "GYD",
  },
  {
    label: "Haiti",
    code: "HTI",
    "country-code": "332",
    currency: "HTG",
  },
  {
    label: "Heard Island and McDonald Islands",
    code: "HMD",
    "country-code": "334",
    currency: null,
  }, // Heard Island and McDonald Islands don't have a specific currency
  {
    label: "Holy See",
    code: "VAT",
    "country-code": "336",
    currency: "EUR",
  },
  {
    label: "Honduras",
    code: "HND",
    "country-code": "340",
    currency: "HNL",
  },
  {
    label: "Hong Kong",
    code: "HKG",
    "country-code": "344",
    currency: "HKD",
  },
  {
    label: "Hungary",
    code: "HUN",
    "country-code": "348",
    currency: "HUF",
  },
  {
    label: "Iceland",
    code: "ISL",
    "country-code": "352",
    currency: "ISK",
  },
  {
    label: "India",
    code: "IND",
    "country-code": "356",
    currency: "INR",
  },
  {
    label: "Indonesia",
    code: "IDN",
    "country-code": "360",
    currency: "IDR",
  },
  {
    label: "Iran (Islamic Republic of)",
    code: "IRN",
    "country-code": "364",
    currency: "IRR",
  },
  { label: "Iraq", code: "IRQ", "country-code": "368", currency: "IQD" },
  {
    label: "Ireland",
    code: "IRL",
    "country-code": "372",
    currency: "EUR",
  },
  {
    label: "Isle of Man",
    code: "IMN",
    "country-code": "833",
    currency: "GBP",
  },
  {
    label: "Israel",
    code: "ISR",
    "country-code": "376",
    currency: "ILS",
  },
  {
    label: "Italy",
    code: "ITA",
    "country-code": "380",
    currency: "EUR",
  },
  {
    label: "Jamaica",
    code: "JAM",
    "country-code": "388",
    currency: "JMD",
  },
  {
    label: "Japan",
    code: "JPN",
    "country-code": "392",
    currency: "JPY",
  },
  {
    label: "Jersey",
    code: "JEY",
    "country-code": "832",
    currency: "GBP",
  },
  {
    label: "Jordan",
    code: "JOR",
    "country-code": "400",
    currency: "JOD",
  },
  {
    label: "Kazakhstan",
    code: "KAZ",
    "country-code": "398",
    currency: "KZT",
  },
  {
    label: "Kenya",
    code: "KEN",
    "country-code": "404",
    currency: "KES",
  },
  {
    label: "Kiribati",
    code: "KIR",
    "country-code": "296",
    currency: "AUD",
  },
  {
    label: "Korea (Democratic People's Republic of)",
    code: "PRK",
    "country-code": "408",
    currency: "KPW",
  },
  {
    label: "Korea, Republic of",
    code: "KOR",
    "country-code": "410",
    currency: "KRW",
  },
  {
    label: "Kuwait",
    code: "KWT",
    "country-code": "414",
    currency: "KWD",
  },
  {
    label: "Kyrgyzstan",
    code: "KGZ",
    "country-code": "417",
    currency: "KGS",
  },
  {
    label: "Lao People's Democratic Republic",
    code: "LAO",
    "country-code": "418",
    currency: "LAK",
  },
  {
    label: "Latvia",
    code: "LVA",
    "country-code": "428",
    currency: "EUR",
  },
  {
    label: "Lebanon",
    code: "LBN",
    "country-code": "422",
    currency: "LBP",
  },
  {
    label: "Lesotho",
    code: "LSO",
    "country-code": "426",
    currency: "LSL",
  },
  {
    label: "Liberia",
    code: "LBR",
    "country-code": "430",
    currency: "LRD",
  },
  {
    label: "Libya",
    code: "LBY",
    "country-code": "434",
    currency: "LYD",
  },
  {
    label: "Liechtenstein",
    code: "LIE",
    "country-code": "438",
    currency: "CHF",
  },
  {
    label: "Lithuania",
    code: "LTU",
    "country-code": "440",
    currency: "EUR",
  },
  {
    label: "Luxembourg",
    code: "LUX",
    "country-code": "442",
    currency: "EUR",
  },
  {
    label: "Macao",
    code: "MAC",
    "country-code": "446",
    currency: "MOP",
  },
  {
    label: "Madagascar",
    code: "MDG",
    "country-code": "450",
    currency: "MGA",
  },
  {
    label: "Malawi",
    code: "MWI",
    "country-code": "454",
    currency: "MWK",
  },
  {
    label: "Malaysia",
    code: "MYS",
    "country-code": "458",
    currency: "MYR",
  },
  {
    label: "Maldives",
    code: "MDV",
    "country-code": "462",
    currency: "MVR",
  },
  { label: "Mali", code: "MLI", "country-code": "466", currency: "XOF" },
  {
    label: "Malta",
    code: "MLT",
    "country-code": "470",
    currency: "EUR",
  },
  {
    label: "Marshall Islands",
    code: "MHL",
    "country-code": "584",
    currency: "USD",
  },
  {
    label: "Martinique",
    code: "MTQ",
    "country-code": "474",
    currency: "EUR",
  },
  {
    label: "Mauritania",
    code: "MRT",
    "country-code": "478",
    currency: "MRU",
  },
  {
    label: "Mauritius",
    code: "MUS",
    "country-code": "480",
    currency: "MUR",
  },
  {
    label: "Mayotte",
    code: "MYT",
    "country-code": "175",
    currency: "EUR",
  },
  {
    label: "Mexico",
    code: "MEX",
    "country-code": "484",
    currency: "MXN",
  },
  {
    label: "Micronesia (Federated States of)",
    code: "FSM",
    "country-code": "583",
    currency: "USD",
  },
  {
    label: "Moldova, Republic of",
    code: "MDA",
    "country-code": "498",
    currency: "MDL",
  },
  {
    label: "Monaco",
    code: "MCO",
    "country-code": "492",
    currency: "EUR",
  },
  {
    label: "Mongolia",
    code: "MNG",
    "country-code": "496",
    currency: "MNT",
  },
  {
    label: "Montenegro",
    code: "MNE",
    "country-code": "499",
    currency: "EUR",
  },
  {
    label: "Montserrat",
    code: "MSR",
    "country-code": "500",
    currency: "XCD",
  },
  {
    label: "Morocco",
    code: "MAR",
    "country-code": "504",
    currency: "MAD",
  },
  {
    label: "Mozambique",
    code: "MOZ",
    "country-code": "508",
    currency: "MZN",
  },
  {
    label: "Myanmar",
    code: "MMR",
    "country-code": "104",
    currency: "MMK",
  },
  {
    label: "Namibia",
    code: "NAM",
    "country-code": "516",
    currency: "NAD",
  },
  {
    label: "Nauru",
    code: "NRU",
    "country-code": "520",
    currency: "AUD",
  },
  {
    label: "Nepal",
    code: "NPL",
    "country-code": "524",
    currency: "NPR",
  },
  {
    label: "Netherlands",
    code: "NLD",
    "country-code": "528",
    currency: "EUR",
  },
  {
    label: "New Caledonia",
    code: "NCL",
    "country-code": "540",
    currency: "XPF",
  },
  {
    label: "New Zealand",
    code: "NZL",
    "country-code": "554",
    currency: "NZD",
  },
  {
    label: "Nicaragua",
    code: "NIC",
    "country-code": "558",
    currency: "NIO",
  },
  {
    label: "Niger",
    code: "NER",
    "country-code": "562",
    currency: "XOF",
  },
  {
    label: "Nigeria",
    code: "NGA",
    "country-code": "566",
    currency: "NGN",
  },
  { label: "Niue", code: "NIU", "country-code": "570", currency: "NZD" },
  {
    label: "Norfolk Island",
    code: "NFK",
    "country-code": "574",
    currency: "AUD",
  },
  {
    label: "North Macedonia",
    code: "MKD",
    "country-code": "807",
    currency: "MKD",
  },
  {
    label: "Northern Mariana Islands",
    code: "MNP",
    "country-code": "580",
    currency: "USD",
  },
  {
    label: "Norway",
    code: "NOR",
    "country-code": "578",
    currency: "NOK",
  },
  { label: "Oman", code: "OMN", "country-code": "512", currency: "OMR" },
  {
    label: "Pakistan",
    code: "PAK",
    "country-code": "586",
    currency: "PKR",
  },
  {
    label: "Palau",
    code: "PLW",
    "country-code": "585",
    currency: "USD",
  },
  {
    label: "Palestine, State of",
    code: "PSE",
    "country-code": "275",
    currency: "ILS",
  },
  {
    label: "Panama",
    code: "PAN",
    "country-code": "591",
    currency: "USD",
  },
  {
    label: "Papua New Guinea",
    code: "PNG",
    "country-code": "598",
    currency: "PGK",
  },
  {
    label: "Paraguay",
    code: "PRY",
    "country-code": "600",
    currency: "PYG",
  },
  { label: "Peru", code: "PER", "country-code": "604", currency: "PEN" },
  {
    label: "Philippines",
    code: "PHL",
    "country-code": "608",
    currency: "PHP",
  },
  {
    label: "Pitcairn",
    code: "PCN",
    "country-code": "612",
    currency: "NZD",
  },
  {
    label: "Poland",
    code: "POL",
    "country-code": "616",
    currency: "PLN",
  },
  {
    label: "Portugal",
    code: "PRT",
    "country-code": "620",
    currency: "EUR",
  },
  {
    label: "Puerto Rico",
    code: "PRI",
    "country-code": "630",
    currency: "USD",
  },
  {
    label: "Qatar",
    code: "QAT",
    "country-code": "634",
    currency: "QAR",
  },
  {
    label: "Réunion",
    code: "REU",
    "country-code": "638",
    currency: "EUR",
  },
  {
    label: "Romania",
    code: "ROU",
    "country-code": "642",
    currency: "RON",
  },
  {
    label: "Russian Federation",
    code: "RUS",
    "country-code": "643",
    currency: "RUB",
  },
  {
    label: "Rwanda",
    code: "RWA",
    "country-code": "646",
    currency: "RWF",
  },
  {
    label: "Saint Barthélemy",
    code: "BLM",
    "country-code": "652",
    currency: "EUR",
  },
  {
    label: "Saint Helena, Ascension and Tristan da Cunha",
    code: "SHN",
    "country-code": "654",
    currency: "SHP",
  },
  {
    label: "Saint Kitts and Nevis",
    code: "KNA",
    "country-code": "659",
    currency: "XCD",
  },
  {
    label: "Saint Lucia",
    code: "LCA",
    "country-code": "662",
    currency: "XCD",
  },
  {
    label: "Saint Martin (French part)",
    code: "MAF",
    "country-code": "663",
    currency: "EUR",
  },
  {
    label: "Saint Pierre and Miquelon",
    code: "SPM",
    "country-code": "666",
    currency: "EUR",
  },
  {
    label: "Saint Vincent and the Grenadines",
    code: "VCT",
    "country-code": "670",
    currency: "XCD",
  },
  {
    label: "Samoa",
    code: "WSM",
    "country-code": "882",
    currency: "WST",
  },
  {
    label: "San Marino",
    code: "SMR",
    "country-code": "674",
    currency: "EUR",
  },
  {
    label: "Sao Tome and Principe",
    code: "STP",
    "country-code": "678",
    currency: "STN",
  },
  {
    label: "Saudi Arabia",
    code: "SAU",
    "country-code": "682",
    currency: "SAR",
  },
  {
    label: "Senegal",
    code: "SEN",
    "country-code": "686",
    currency: "XOF",
  },
  {
    label: "Serbia",
    code: "SRB",
    "country-code": "688",
    currency: "RSD",
  },
  {
    label: "Seychelles",
    code: "SYC",
    "country-code": "690",
    currency: "SCR",
  },
  {
    label: "Sierra Leone",
    code: "SLE",
    "country-code": "694",
    currency: "SLL",
  },
  {
    label: "Singapore",
    code: "SGP",
    "country-code": "702",
    currency: "SGD",
  },
  {
    label: "Sint Maarten (Dutch part)",
    code: "SXM",
    "country-code": "534",
    currency: "ANG",
  },

  {
    label: "Slovakia",
    code: "SVK",
    "country-code": "703",
    currency: "EUR",
  },
  {
    label: "Slovenia",
    code: "SVN",
    "country-code": "705",
    currency: "EUR",
  },
  {
    label: "Solomon Islands",
    code: "SLB",
    "country-code": "090",
    currency: "SBD",
  },
  {
    label: "Somalia",
    code: "SOM",
    "country-code": "706",
    currency: "SOS",
  },
  {
    label: "South Africa",
    code: "ZAF",
    "country-code": "710",
    currency: "ZAR",
  },
  {
    label: "South Georgia and the South Sandwich Islands",
    code: "SGS",
    "country-code": "239",
    currency: "GBP",
  },
  {
    label: "South Sudan",
    code: "SSD",
    "country-code": "728",
    currency: "SSP",
  },
  {
    label: "Spain",
    code: "ESP",
    "country-code": "724",
    currency: "EUR",
  },
  {
    label: "Sri Lanka",
    code: "LKA",
    "country-code": "144",
    currency: "LKR",
  },
  {
    label: "Sudan",
    code: "SDN",
    "country-code": "729",
    currency: "SDG",
  },
  {
    label: "Suriname",
    code: "SUR",
    "country-code": "740",
    currency: "SRD",
  },
  {
    label: "Svalbard and Jan Mayen",
    code: "SJM",
    "country-code": "744",
    currency: "NOK",
  },
  {
    label: "Sweden",
    code: "SWE",
    "country-code": "752",
    currency: "SEK",
  },
  {
    label: "Switzerland",
    code: "CHE",
    "country-code": "756",
    currency: "CHF",
  },
  {
    label: "Syrian Arab Republic",
    code: "SYR",
    "country-code": "760",
    currency: "SYP",
  },
  {
    label: "Taiwan, Province of China",
    code: "TWN",
    "country-code": "158",
    currency: "TWD",
  },
  {
    label: "Tajikistan",
    code: "TJK",
    "country-code": "762",
    currency: "TJS",
  },
  {
    label: "Tanzania, United Republic of",
    code: "TZA",
    "country-code": "834",
    currency: "TZS",
  },
  {
    label: "Thailand",
    code: "THA",
    "country-code": "764",
    currency: "THB",
  },
  {
    label: "Timor-Leste",
    code: "TLS",
    "country-code": "626",
    currency: "USD",
  },
  { label: "Togo", code: "TGO", "country-code": "768", currency: "XOF" },
  {
    label: "Tokelau",
    code: "TKL",
    "country-code": "772",
    currency: "NZD",
  },
  {
    label: "Tonga",
    code: "TON",
    "country-code": "776",
    currency: "TOP",
  },
  {
    label: "Trinidad and Tobago",
    code: "TTO",
    "country-code": "780",
    currency: "TTD",
  },
  {
    label: "Tunisia",
    code: "TUN",
    "country-code": "788",
    currency: "TND",
  },
  {
    label: "Turkey",
    code: "TUR",
    "country-code": "792",
    currency: "TRY",
  },
  {
    label: "Turkmenistan",
    code: "TKM",
    "country-code": "795",
    currency: "TMT",
  },
  {
    label: "Turks and Caicos Islands",
    code: "TCA",
    "country-code": "796",
    currency: "USD",
  },
  {
    label: "Tuvalu",
    code: "TUV",
    "country-code": "798",
    currency: "AUD",
  },
  {
    label: "Uganda",
    code: "UGA",
    "country-code": "800",
    currency: "UGX",
  },
  {
    label: "Ukraine",
    code: "UKR",
    "country-code": "804",
    currency: "UAH",
  },
  {
    label: "United Arab Emirates",
    code: "ARE",
    "country-code": "784",
    currency: "AED",
  },
  {
    label: "United Kingdom of Great Britain and Northern Ireland",
    code: "GBR",
    "country-code": "826",
    currency: "GBP",
  },
  {
    label: "United States of America",
    code: "USA",
    "country-code": "840",
    currency: "USD",
  },
  {
    label: "United States Minor Outlying Islands",
    code: "UMI",
    "country-code": "581",
    currency: "USD",
  },
  {
    label: "Uruguay",
    code: "URY",
    "country-code": "858",
    currency: "UYU",
  },
  {
    label: "Uzbekistan",
    code: "UZB",
    "country-code": "860",
    currency: "UZS",
  },
  {
    label: "Vanuatu",
    code: "VUT",
    "country-code": "548",
    currency: "VUV",
  },
  {
    label: "Venezuela (Bolivarian Republic of)",
    code: "VEN",
    "country-code": "862",
    currency: "VES",
  },
  {
    label: "Viet Nam",
    code: "VNM",
    "country-code": "704",
    currency: "VND",
  },
  {
    label: "Virgin Islands (British)",
    code: "VGB",
    "country-code": "092",
    currency: "USD",
  },
  {
    label: "Virgin Islands (U.S.)",
    code: "VIR",
    "country-code": "850",
    currency: "USD",
  },
  {
    label: "Wallis and Futuna",
    code: "WLF",
    "country-code": "876",
    currency: "XPF",
  },
  {
    label: "Western Sahara",
    code: "ESH",
    "country-code": "732",
    currency: "MAD",
  },
  {
    label: "Yemen",
    code: "YEM",
    "country-code": "887",
    currency: "YER",
  },
  {
    label: "Zambia",
    code: "ZMB",
    "country-code": "894",
    currency: "ZMW",
  },
  {
    label: "Zimbabwe",
    code: "ZWE",
    "country-code": "716",
    currency: "ZWL",
  },
];

export const iso2Countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: 'Cote D"Ivoire', code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: 'Korea, Democratic People"S Republic of', code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: 'Lao People"S Democratic Republic', code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];
