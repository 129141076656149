import {
  Autocomplete,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import "./style.css";
import { useEffect, useState, useMemo } from "react";
import Iconify from "src/components/Iconify";
import useAuth from "src/hooks/useAuth";
import useSettings from "src/hooks/useSettings";
import excmark from "src/images/people.png";
import axiosInstance from "src/utils/axios";
import { LoadingButton } from "@mui/lab";
import useUsersList from "src/components/UsersAutoComplete/hooks/useUsersList";
import { useSnackbar } from "notistack";
import useLocales from "src/hooks/useLocales";
const HoldingDashboardModal = ({ handleClose, open }) => {
  const { user, getUser } = useAuth();
  const { themeStretch } = useSettings();
  const usersList = useUsersList("network");
  const [userId, setUserId] = useState(null);
  const [openNetwork, setOpenNetwork] = useState();
  const [addingToBinary, setAddingToBinary] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [errorText, setErrorText] = useState("");

  // useEffect(() => {
  //   if (user?.holding_tank === 1) handleOpen();
  // }, [user]);

  const selectedUser = useMemo(() => {
    if (userId) {
      return usersList.find(({ user_id }) => user_id === userId);
    }
    return null;
  }, [userId]);

  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpenNetwork(true);
  };
  const handleClickCLose = () => {
    setOpenNetwork(false);
  };
  const { enqueueSnackbar } = useSnackbar();

  const addToBinary = async () => {
    setAddingToBinary(true);
    const reqData = new FormData();
    if (userId) {
      reqData.append("sponsor", userId);
    }
    reqData.append("user_id", user?.id);

    try {
      const { status, data } = await axiosInstance.post(
        `api/user/add-to-tree`,
        reqData
      );
      if (status === 200) {
        setAddingToBinary(false);
        enqueueSnackbar(data.message);
        setErrorText("");
        handleClickCLose();
        handleClose();
        getUser();
      }
    } catch (err) {
      setErrorText("");
      setAddingToBinary(false);
      setErrorText(err?.errors.sponsor);
    }
  };
  const { translate } = useLocales();
  return (
    <div>
      <Dialog open={open} onClose={handleClickCLose} fullWidth maxWidth="xs">
        <DialogTitle>{translate("join")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box
              sx={{
                display: "grid",
                rowGap: 3,
                columnGap: 2,
                marginTop: 3,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Box>
                <Autocomplete
                  onChange={(_, v) => {
                    if (v) {
                      const { user_id } = v;
                      setUserId(user_id);
                    } else {
                      setUserId(null);
                    }
                  }}
                  value={selectedUser}
                  options={usersList}
                  getOptionLabel={(option) => option.username}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="user_id"
                      label={translate("sponsor_name")}
                      variant="outlined"
                      size="small"
                      error={errorText}
                      helperText={
                        <Typography variant="caption" color="error">
                          {errorText}
                        </Typography>
                      }
                    />
                  )}
                />
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="close" onClick={handleClose} color="error">
            {translate("Close")}
          </Button>
          <LoadingButton
            loading={addingToBinary}
            variant="contained"
            onClick={addToBinary}
          >
            {translate("Add")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HoldingDashboardModal;
