import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import {
  FormProvider,
  RHFCheckbox,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import { number, string } from "yup";
import Iconify from "src/components/Iconify";
import useAuth from "src/hooks/useAuth";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import * as Yup from "yup";
import useLocales from "src/hooks/useLocales";

export default function RegisterForm() {
  const { register } = useAuth();
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const { uname } = useParams();
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const usernameRegex = /^[\w-]*$/;
  const passwordRegex = /^\S+$/;
  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email(translate("main.Emailmustbeavalidemailaddress"))
      .required(translate("main.Emailisrequired")),

    username: Yup.string()
      .required(translate("main.UserNameisrequired"))
      .trim()
      .matches(
        usernameRegex,
        translate("main.Thisfieldcannotcontainwhitespaceandspecialcharacter")
      ),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("New Password is required")
      .matches(passwordRegex, "This field cannot contain white space"),
    repassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Password must match"
    ),
    // referral: Yup.string().required("Referral Id is required"),
    checkvalue: Yup.bool().oneOf(
      [true],
      "You need to accept the terms and conditions"
    ),
    company_name: Yup.string()
      .typeError("Company Name is Required")
      .when("user_type", {
        is: (user_type) => user_type === `vendor`,
        then: Yup.string().required("Company name is Required"),
      })
      .nullable(),
    user_type: string().required("User Type is required"),
  });

  const defaultValues = {
    email: "",
    username: "",
    password: "",
    repassword: "",
    referral: "sparissimo",
    user_type: "",
    company_name: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await register(
        data.email,
        data.username,
        data.password,
        data.referral,
        data.user_type,
        data.company_name
      );

      // enqueueSnackbar("Successfully Registered");
      // window.location = "/user/dashboard";
      // window.location = "/auth/login";
    } catch (error) {
      console.error(error);

      Object.entries(error.errors).forEach(([k, v]) =>
        setError(k, { message: v[0] })
      );

      // reset();
      // setError("afterSubmit", {
      //   message: error.message.toString(),
      // });
    }
  };

  useEffect(() => {
    if (uname) {
      methods.setValue("referral", uname);
    }
  }, [uname]);

  const showInput = watch("user_type");
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}
        <RHFSelect
          name="user_type"
          placeholder={translate("main.SelectUserType")}
        >
          <option value="">{translate("main.SelectUserType")}</option>
          <option value="private">{translate("main.Private")}</option>
          <option value="vendor">{translate("main.Vendor")}</option>
          <option value="associations">{translate("main.Associations")}</option>
        </RHFSelect>

        <RHFTextField name="email" label={translate("main.Emailaddress")} />
        {showInput === "vendor" && (
          <>
            <RHFTextField name="company_name" label="Company Name *" />
          </>
        )}
        <RHFTextField name="username" label={translate("main.UserName")} />
        <RHFTextField
          name="password"
          label={translate("main.Password")}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  tabIndex={-1}
                  edge="end"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="repassword"
          label={translate("main.ReEnterPassword")}
          type={showRePassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  tabIndex={-1}
                  edge="end"
                  onClick={() => setShowRePassword(!showRePassword)}
                >
                  <Iconify
                    icon={showRePassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="referral"
          label={translate("main.ReferralID")}
          disabled={Boolean(uname)}
        />
        {/* 
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <Typography
            variant="body2"
            align="center"
            sx={{ color: "text.secondary", mt: 0 }}
          >
            <RHFCheckbox name="agree" label="I agree to Bpract MLM" />
            <Link underline="always" color="text.primary" href="#">
              Terms of Service
            </Link>
            &nbsp; & &nbsp;
            <Link underline="always" color="text.primary" href="#">
              Privacy Policy
            </Link>
          </Typography>
        </Stack> */}

        <FormGroup style={{ margin: "0px 0px 5px 0px" }}>
          <FormControlLabel
            style={{ margin: "5px 0px 0px 0px" }}
            control={<Checkbox name="checkvalue" required />}
            label=""
          />

          <Typography
            style={{
              margin: "-32px 2px 0px 32px",
              width: "fit-content",
              fontSize: "12px",
              lineHeight: "16px",
            }}
          >
            {translate("main.Byregistering")}{" "}
            <a href="https://www.sparissimo.com/agb" target="_blank">
              {translate("main.terms")}
            </a>{" "}
            {translate("main.andconditions")}{" "}
            <a href="https://www.sparissimo.com/datenschutz" target="_blank">
              {translate("main.dataprotection")}
            </a>{" "}
            {translate("main.conditionsand")}
          </Typography>
        </FormGroup>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {translate("main.newRegister")}
        </LoadingButton>

        {/* <a
          href="https://www.sparissimo.com/impressum"
          target="_blank"
          style={{
            cursor: "pointer",
            // padding: "0.1rem",
            textDecoration: "none",
            textAlign: "left",
            fontSize: "12px",
          }}
        >
          <p>
            Visit website :
            <a href="https://www.sparissimo.com/agb" target="_blank">
              Sparissimo
            </a>
          </p>
        </a> */}
      </Stack>
    </FormProvider>
  );
}
