import { Box, Card, Grid } from "@mui/material";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import { PATH_DASHBOARD } from "src/routes/paths";
import useFetchSummary from "../hooks/useFetchSummary";
import Cards from "./cards";
import DataList from "./dataList";
import useFundTransfer from "./hooks/useFundTransfer";
import SendForm from "./sendForm";
import useLocales from "src/hooks/useLocales";
import useAuth from "src/hooks/useAuth";

const Index = () => {
    const { translate } = useLocales();
    const _data = [
        {
            title: translate("fundtransfer.eWalletBalance"),
            color: "#795548",
            icon: "entypo:wallet",
            key: "ewallet_balance",
        },
        // {
        //   title: translate("fundtransfer.depositWalletBalance"),
        //   color: "#607d8b",
        //   icon: "fluent:wallet-32-filled",
        //   key: "deposit_wallet_balance",
        // },
    ];
    const { summary, fetchDataSummary } = useFetchSummary("fund-transfer-data");
    const { state, refetch, ...rest } = useFundTransfer();
    const { user } = useAuth();
    return (
        <div>
            <Page title="Fund Transfer: Financial">
                <Box sx={{ p: 2 }}>
                    <HeaderBreadcrumbs
                        heading={translate("fundtransfer.fundtransfer")}
                        links={[
                            {
                                name: translate("fundtransfer.dashboard"),
                                href: PATH_DASHBOARD.root,
                            },
                            { name: translate("fundtransfer.fundtransfer") },
                        ]}
                    />
                    {/*    <Card sx={{ p: 2 }}>
            <Grid container spacing={3}>
              {_data.map((props) => (
                <Grid item xs={12} md={4}>
                  <Cards
                    {...props}
                    currency={user.currency}
                    total={summary[props.key]}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} md={12}>
              <SendForm refetch={refetch} fetchData={fetchDataSummary} />
            </Grid>
          </Card>
          */}

                    <Grid item xs={12} md={12}>
                        <DataList state={state} currency={user.currency} {...rest} />
                    </Grid>
                </Box>
            </Page>
        </div>
    );
};

export default Index;
