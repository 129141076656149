import { lazy } from "react";
import { Navigate } from "react-router";
import DepositWallet from "src/pages/userSide/financial/deposit";
import Ewallet from "src/pages/userSide/financial/ewallet";
import FundTransfer from "src/pages/userSide/financial/fundTransfer";
import Loadable from "../Loadable";
import { HideMenus } from "src/guards/AuthGuard";

const AddCredit = Loadable(
  lazy(() => import("src/pages/userSide/financial/deposit/addCredit"))
);

const RequestPayout = Loadable(
  lazy(() => import("src/pages/userSide/financial/requestPayout/index"))
);

const financial = {
  path: "financial",
  children: [
    { index: true, element: <Navigate to="e-wallet" /> },
    { path: "e-wallet", element: <Ewallet /> },
    {
      path: "deposit-wallet",
      children: [
        { index: true, element: <DepositWallet /> },
        { path: "add-credit", element: <AddCredit /> },
      ],
    },
    {
      path: "funds-transfer",
      element: (
        <HideMenus>
          <FundTransfer />
        </HideMenus>
      ),
    },
    { path: "payout", element: <RequestPayout /> },
  ],
};

export default financial;
