import { useRoutes } from "react-router-dom";

import PDFViewer from "../components/pdfViewer";
import auth from "./auth";
import dashboard from "./dashboard";
import main from "./main";
import user from "./user";

const Router = () => {
  return useRoutes([
    { ...auth },
    { ...user },
    { ...dashboard },
    ...main,
    { path: "/pdf", element: <PDFViewer /> },
  ]);
};

export default Router;
