import { useEffect } from "react";
import NotistackProvider from "./components/NotistackProvider";
import { ProgressBarStyle } from "./components/ProgressBar";
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import ThemeColorPresets from "./components/ThemeColorPresets";
import ThemeLocalization from "./components/ThemeLocalization";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import { ChartStyle } from "./components/chart";
import FirstLogin from "./components/first-login";
import Settings from "./components/settings";
import Ternary from "./components/ternary";
import FloatingAction from "./floating-action";
import useAuth from "./hooks/useAuth";
import MetaMaskProvider from "./pages/stake/store";
import Router from "./routes";
import ThemeProvider from "./theme";

export default function App() {
  const { getUser, user } = useAuth();

  useEffect(() => {
    if (user && !Object.keys(user).length) {
      getUser();
      return;
    }
  }, [user]);
  const isImpersonate = Boolean(localStorage.getItem("isImpersonate"));
  return (
    <MetaMaskProvider>
      <ThemeProvider>
        <ThemeColorPresets>
          <ThemeLocalization>
            <RtlLayout>
              <NotistackProvider>
                <MotionLazyContainer>
                  <ProgressBarStyle />
                  <ChartStyle />
                  <Settings />
                  <ScrollToTop />
                  <Router />
                  {/* <Ternary
                    when={Object.keys(user || {}).length > 0 && !isImpersonate}
                    then={<FloatingAction />}
                  /> */}
                </MotionLazyContainer>
              </NotistackProvider>
            </RtlLayout>
          </ThemeLocalization>
        </ThemeColorPresets>
      </ThemeProvider>
    </MetaMaskProvider>
  );
}
