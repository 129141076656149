import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "../Loadable";

const UserProfile = Loadable(
  lazy(() => import("src/pages/dashboard/UserProfile"))
);

const Profile = Loadable(
  lazy(() => import("src/sections/@dashboard/user/profile/Profile"))
);

const EditProfile = Loadable(
  lazy(() => import("src/sections/@dashboard/user/profile/EditInfo"))
);

const ProfileSettings = Loadable(
  lazy(() =>
    import("src/sections/@dashboard/user/profile/ProfileAccountSettings")
  )
);

const ProfileReferrals = Loadable(
  lazy(() => import("src/pages/userSide/profile/subPages/referrals/index"))
);
const ProfileNotes = Loadable(
  lazy(() => import("src/sections/@dashboard/user/profile/notes/index.jsx"))
);

const UserCards = Loadable(lazy(() => import("src/pages/dashboard/UserCards")));
const UserList = Loadable(lazy(() => import("src/pages/dashboard/UserList")));
const UserAccount = Loadable(
  lazy(() => import("src/pages/dashboard/UserAccount"))
);
const UserCreate = Loadable(
  lazy(() => import("src/pages/dashboard/UserCreate"))
);

const user = {
  path: "user",
  children: [
    {
      element: <Navigate to="/dashboard/user/profile" replace />,
      index: true,
    },
    {
      path: "profile",
      element: <UserProfile />,
      children: [
        {
          element: <Profile />,
          index: true,
        },
        {
          path: "edit",
          element: <EditProfile />,
        },
        { path: "settings", element: <ProfileSettings /> },
        { path: "referrals", element: <ProfileReferrals /> },
        { path: "notes", element: <ProfileNotes /> },
      ],
    },
    { path: "cards", element: <UserCards /> },
    { path: "list", element: <UserList /> },
    { path: "new", element: <UserCreate /> },
    { path: ":name/edit", element: <UserCreate /> },
    { path: "account", element: <UserAccount /> },
  ],
};

export default user;
