import { PATH_DASHBOARD } from "./routes/paths";
export const DATE_FORMAT = "DD MMM YYYY";

export const USER_PURCHASE_STATUS = {
	active: "ACTIVE",
	inactive: "INACITVE",
	none: "NONE",
};
// API
// ----------------------------------------------------------------------
export const TOKEN_CURRENCY_SYMBOL = "$";
export const HOST_API = process.env.REACT_APP_HOST_NAME || "";
export const CHAT_HOST_API = process.env.REACT_APP_CHAT_HOST_NAME || "";
export const TOKEN_CONTRACT = process.env.REACT_APP_TOKEN_CONTRACT || "";
export const STAKING_CONTRACT = process.env.REACT_APP_STAKING_CONTRACT || "";

export const TOKEN_CONTRACT_OLD =
	process.env.REACT_APP_TOKEN_CONTRACT_OLD || "";
export const STAKING_CONTRACT_OLD =
	process.env.REACT_APP_STAKING_CONTRACT_OLD || "";

export const STAKING_MODE = process.env.REACT_APP_STAKING_MODE || "bsc_test";
export const FIREBASE_API = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APPID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
	userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
	clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
	clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
	domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
	MOBILE_HEIGHT: 64,
	MAIN_DESKTOP_HEIGHT: 88,
	DASHBOARD_DESKTOP_HEIGHT: 92,
	DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
	BASE_WIDTH: 260,
	DASHBOARD_WIDTH: 280,
	DASHBOARD_COLLAPSE_WIDTH: 88,
	//
	DASHBOARD_ITEM_ROOT_HEIGHT: 48,
	DASHBOARD_ITEM_SUB_HEIGHT: 40,
	DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
	NAVBAR_ITEM: 22,
	NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
	themeMode: "light",
	themeDirection: "ltr",
	themeColorPresets: "default",
	themeLayout: "horizontal",
	themeStretch: false,
};
