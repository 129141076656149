import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Ternary from "src/components/ternary";
import LoadingScreen from "../components/LoadingScreen";
import useAuth from "../hooks/useAuth";
import Login from "../pages/auth/Login";

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export const HideForImpersonate = ({ children }) => {
  const isNotImpersonate = useRef(localStorage.isImpersonate !== "true");

  return <Ternary when={isNotImpersonate.current} then={children} />;
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}

export const AdminGuard = ({ children }) => {
  const { isAdmin, isSubAdmin } = useAuth();
  if (isAdmin || isSubAdmin) {
    return <>{children}</>;
  }
  return <Navigate to="/user" />;
};

export const UserGuard = ({ children }) => {
  const { isAdmin, isSubAdmin } = useAuth();
  if (isAdmin || isSubAdmin) {
    return <Navigate to="/dashboard/app/business" />;
  }

  return <>{children}</>;
};

export const HideMenus = ({ children }) => {
  // const { user } = useAuth();
  // const isHolding = user?.holding_tank === 0;
  // if (isHolding) return <>{children}</>;

  const isNetworkUser = JSON.parse(localStorage.getItem("is_network_user"));
  if (isNetworkUser) return <>{children}</>;

  return <Navigate to="/404" replace />;
};

export const HidePartner = ({ children }) => {
  // const { user } = useAuth();
  // const isHolding = user?.holding_tank === 0;
  // if (isHolding || user.sponsor_info != null) return <>{children}</>;
  const isNetworkUser = JSON.parse(localStorage.getItem("is_network_user"));

  if (isNetworkUser) return <>{children}</>;
  return <Navigate to="/404" replace />;
};
