import useSettings from "src/hooks/useSettings";
import Horizontal from "../components/horizontal";
import Vertical from "../components/vertical";
import { default as navConfig, default as useNavConfig } from "./NavConfig";

const DashboardLayout = () => {
  const { themeLayout } = useSettings();
  const navConfig = useNavConfig();

  const verticalLayout = themeLayout === "vertical";
  return verticalLayout ? (
    <Vertical navConfig={navConfig} />
  ) : (
    <Horizontal navConfig={navConfig} />
  );
};

export default DashboardLayout;
