import { Badge, Stack, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Ternary from "src/components/ternary";
import useLastSeen from "./hooks/use-last-seen";
import groupPlaceHolder from "./images/group.png";
import placeHolder from "./images/place_holder.png";
import "./style.css";

const Recent = ({ setShowComponentB, users = 0, setShowGroup }) => {
  const haveChats = users.length > 0;

  return (
    <>
      <Stack
        className="scrollChat"
        sx={{
          maxHeight: 300,
          overflow: "auto",
          mt: 1,
          scrollbarWidth: "thin", // For Firefox
          "&::-webkit-scrollbar": {
            width: "5px", // Width of the scrollbar
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1", // Color of the track
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888", // Color of the thumb
            borderRadius: "1px", // Roundness of the thumb
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555", // Color of the thumb on hover
          },
        }}
      >
        <Ternary
          when={haveChats}
          then={users?.map((data) => {
            return (
              <AuthorItem
                setShowGroup={setShowGroup}
                setShowComponentB={setShowComponentB}
                key={data.id}
                item={data}
              />
            );
          })}
          otherwise={
            <Typography textAlign="center" variant="caption" mt={5}>
              No Recent Chats
            </Typography>
          }
        />
      </Stack>
    </>
  );
};

function AuthorItem({ item, setShowComponentB, setShowGroup }) {
  const {
    username,
    userID,
    online,
    last_seen,
    notification,
    profile_image,
    unreadCount,
    group_name,
    isGroup,
  } = item;

  const handleClick = () => {
    if (isGroup) {
      setShowGroup(group_name);
    } else {
      setShowComponentB(userID);
    }
  };
  const userLastSeen = useLastSeen(last_seen);

  return (
    <>
      {/* Render ViewChat component conditionally */}
      <List
        sx={{
          width: "100%",
          maxWidth: 300,
          bgcolor: "background.paper",
        }}
      >
        <ListItem
          sx={{
            "&:hover": {
              bgcolor: "#ffc10747",
              cursor: "pointer",
            },
          }}
          onClick={handleClick}
          alignItems="flex-start"
        >
          <ListItemAvatar>
            <Ternary
              when={!isGroup}
              then={
                <Badge
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  color={online ? "success" : "warning"}
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                >
                  <Avatar
                    alt={username}
                    src={
                      profile_image
                        ? profile_image
                        : isGroup
                        ? groupPlaceHolder
                        : placeHolder
                    }
                  />
                </Badge>
              }
              otherwise={
                <Avatar
                  alt={group_name}
                  src={
                    profile_image
                      ? profile_image
                      : isGroup
                      ? groupPlaceHolder
                      : placeHolder
                  }
                />
              }
            />
          </ListItemAvatar>
          <ListItemText
            sx={{ color: "black" }}
            primary={
              <Stack direction="row" justifyContent="space-between">
                <Typography>{isGroup ? group_name : username}</Typography>

                <Ternary
                  when={notification}
                  then={
                    <p
                      style={{
                        padding: 0.5,
                        height: "20px",
                        width: "20px",
                        borderRadius: "50%",
                        backgroundColor: "#ff9800",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                        display: "flex",
                        fontSize: "13px",
                      }}
                    >
                      {unreadCount}
                    </p>
                  }
                />
              </Stack>
            }
            secondary={
              <Ternary
                when={!isGroup}
                then={
                  <Ternary
                    when={!online}
                    then={
                      userLastSeen === "offline"
                        ? userLastSeen
                        : `Last Seen: ${userLastSeen}`
                    }
                    otherwise={
                      <Ternary
                        when={notification}
                        then={<>You have an unread message</>}
                        otherwise={<>online</>}
                      />
                    }
                  />
                }
              />
            }
          />
        </ListItem>
      </List>
    </>
  );
}
export default Recent;
