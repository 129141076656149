import React, { useEffect, useState } from "react";
import useErrors from "src/hooks/useErrors";
import axiosInstance from "src/utils/axios";

const useDashboard = () => {
  const [data, setData] = useState([]);
  const handleError = useErrors();
  const fetchData = async () => {
    try {
      const { status, data } = await axiosInstance("api/user/profile-complete/overview_box");
      if (status === 200) {
        setData(data.data);
      }
    } catch (err) {
      handleError(err);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return { data, fetchData };
};

export default useDashboard;
