import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "../Loadable";

const Document = Loadable(
  lazy(() => import("src/pages/userSide/tools/document"))
);

const Videos = Loadable(lazy(() => import("src/pages/userSide/tools/videos")));

const tools = {
  path: "tools",
  children: [
    { index: true, element: <Navigate to="documents" /> },
    { path: "documents", element: <Document /> },
    { path: "videos", element: <Videos /> },
  ],
};

export default tools;
