import { lazy } from "react";
import { Navigate } from "react-router";
import LogoOnlyLayout from "src/layouts/LogoOnlyLayout";
import Register from "src/pages/auth/Register";
import MainLayout from "../layouts/main";
import Loadable from "./Loadable";
import MainPage from "src/components/root_page/mainPage";

const HomePage = Loadable(lazy(() => import("src/pages/Home")));
const About = Loadable(lazy(() => import("src/pages/About")));
const Contact = Loadable(lazy(() => import("src/pages/Contact")));
const ComingSoon = Loadable(lazy(() => import("src/pages/ComingSoon")));
const Maintenance = Loadable(lazy(() => import("src/pages/Maintenance")));
const Pricing = Loadable(lazy(() => import("src/pages/Pricing")));
const Payment = Loadable(lazy(() => import("src/pages/Payment")));
const Page500 = Loadable(lazy(() => import("src/pages/Page500")));
const NotFound = Loadable(lazy(() => import("src/pages/Page404")));
const Success = Loadable(lazy(() => import("src/pages/Success")));
const Failpage = Loadable(lazy(() => import("src/pages/failure")));
const AccessToken = Loadable(lazy(() => import("src/pages/accessToken")));
const SuccessTarget = Loadable(
  lazy(() => import("src/pages/auth/SuccessTarget"))
);
const Redirect = Loadable(lazy(() => import("src/pages/auth/reDirect")));
const main = [
  {
    path: "/",
    // element: <MainPage />,
    children: [
      // { element: <HomePage />, index: true },
      { element: <Navigate to="/auth/login" />, index: true },
      { path: "about-us", element: <About /> },
      { path: "contact-us", element: <Contact /> },
      { path: "bpract", element: <MainPage /> },
      { path: "world-success", element: <SuccessTarget /> },
      { path: "redirect", element: <Redirect /> },
    ],
  },
  {
    path: "*",
    element: <LogoOnlyLayout />,
    children: [
      { path: "coming-soon", element: <ComingSoon /> },
      { path: "maintenance", element: <Maintenance /> },
      { path: "pricing", element: <Pricing /> },
      { path: "payment", element: <Payment /> },
      { path: "500", element: <Page500 /> },
      { path: "404", element: <NotFound /> },
      { path: "success", element: <Success /> },
      { path: "failure", element: <Failpage /> },
      { path: "access-token", element: <AccessToken /> },
      { path: "*", element: <Navigate to="/404" replace /> },
    ],
  },
];

export default main;
