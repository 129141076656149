import React from "react";
import "./style.css";
import { Box, Typography, List, ListItem, Stack } from "@mui/material";

import sliderImg from "src/assets/images/slider.jpg";
import logoregster from "src/images/sparissimo_logo.png";
import comminity from "src/assets/images/communityIcon.jpg";
import OnlineStore from "src/assets/images/onlinIcon.jpg";
import Regional from "src/assets/images/regnlIcon.jpg";
import Restaurants from "src/assets/images/restrntIcon.jpg";
import Cashback from "src/assets/images/casbackIcon.jpg";
import cashbackImg from "src/assets/images/cashbackimg.png";
import cashbackBG from "src/assets/images/cashbackbg.jpg";
import { RegisterForm } from "src/sections/auth/register";
import LanguagePopover from "src/layouts/shared/header/LanguagePopover";
import useLocales from "src/hooks/useLocales";

export default function App() {
  const { translate } = useLocales();
  return (
    <Box className="regsterpage">
      {/* ==================================== */}
      <Box className="headerBox container">
        <img className="sparissimoLogo" src={logoregster} />

        <List className="menuUl">
          <ListItem>
            <LanguagePopover />
          </ListItem>
          <ListItem className="mobileHdn">
            {" "}
            <a href="#home">{translate("main.Home")}</a>
          </ListItem>
          <ListItem className="mobileHdn">
            <a href="#About">{translate("main.Aboutus")}</a>
          </ListItem>
          <ListItem className="mobileHdn">
            <a href="#works">{translate("main.Howitworks")}</a>{" "}
          </ListItem>
          <ListItem className="sprsmologinBtn">
            <a name="login" href="/auth/login">
              {translate("main.Login")}{" "}
            </a>
          </ListItem>
        </List>
      </Box>

      {/* ==================================== */}
      <Box className="sliderBox">
        <Box className="slidCntntBox">
          <Typography variant="h1">
            {" "}
            {translate("main.WITHUS")},{" "}
            <span>{translate("main.EVERYONEISA")} </span>{" "}
            {translate("main.Winner")} !{" "}
          </Typography>
          <Typography> {translate("main.generatefood")} </Typography>
        </Box>

        <Box className="slidImgBox">
          <img src={sliderImg} />
        </Box>
      </Box>

      {/* ==================================== */}
      <Box className="firstsectionpage formbox container" id="About">
        <Box className="flrmLeftbox">
          <Typography variant="h2">
            {translate("main.becomePart")}{" "}
            <span>{translate("main.community")}</span>
          </Typography>
          <Typography>{translate("main.weMerge")}</Typography>
          <Typography>{translate("main.Wehavesignedonline")}</Typography>
          <hr className="hr1"></hr>
          <Typography variant="h3">
            {translate("main.Youcanregister")}
          </Typography>

          <List className="introUl">
            <ListItem>{translate("main.REgisterFor")}</ListItem>
            <ListItem>{translate("main.Suitable")}</ListItem>
            <ListItem>{translate("main.useOur")}</ListItem>
            <ListItem>{translate("main.ourshopping")}</ListItem>
          </List>
        </Box>
        <Stack className="formcntnrBox">
          <Box className="formcntnr mt_40">
            <Typography variant="h4" class="mb_20">
              {translate("main.Registerwithus")}
            </Typography>
            <Box className="card">
              <RegisterForm />
            </Box>
          </Box>
        </Stack>
      </Box>
      {/* ================================================= */}
      <Box className="sectionpage secnpadng_servc bglight">
        <Box className="container">
          <Typography className="maintitle text-center" variant="h5">
            {translate("main.Youradvantages")} -{" "}
            <span>{translate("main.Ourservices")}</span>
          </Typography>
          <List className="servc">
            <ListItem>
              <Box className="imageBox">
                <img src={comminity} />
              </Box>
              <Typography className="sevctitle">
                {translate("main.Community")}
              </Typography>
              <Typography className="sevccntnt">
                {translate("main.Networkexchange")}
              </Typography>
            </ListItem>

            <ListItem>
              <Box className="imageBox">
                <img src={OnlineStore} />
              </Box>
              <Typography className="sevctitle">
                {translate("main.OnlineStores")}
              </Typography>
              <Typography className="sevccntnt">
                {translate("main.Over2500")}
              </Typography>
            </ListItem>

            <ListItem>
              <Box className="imageBox">
                <img src={Regional} />
              </Box>
              <Typography className="sevctitle">
                {translate("main.Regional")}
              </Typography>
              <Typography className="sevccntnt">
                {translate("main.Chooseyourshop")}
              </Typography>
            </ListItem>

            <ListItem>
              <Box className="imageBox">
                <img src={Restaurants} />
              </Box>
              <Typography className="sevctitle">
                {translate("main.Restaurants")}
              </Typography>
              <Typography className="sevccntnt">
                {translate("main.Orderyourpizza")}
              </Typography>
            </ListItem>

            <ListItem>
              <Box className="imageBox">
                <img src={Cashback} />
              </Box>
              <Typography className="sevctitle">
                {" "}
                {translate("main.Cashback")}
              </Typography>
              <Typography className="sevccntnt">
                {translate("main.Yougeneratecashback")}
              </Typography>
            </ListItem>
          </List>
        </Box>
      </Box>
      {/* ================================================= */}
      <Box className="sectionpage" id="works">
        <Box className="container">
          <Typography className="maintitle text-center" variant="h5">
            {translate("main.How")}{" "}
            <span>{translate("main.doesSparissimo")}</span>
          </Typography>

          <iframe
            className="iframsiz"
            src="https://www.youtube.com/embed/ZXrburmZ_3g"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Box>
      </Box>
      {/* ================================================= */}
      <Box
        className="sectionpage cashbackBG"
        style={{ backgroundImage: `url(${cashbackBG})` }}
      >
        <Box className="cashbackBGfade"></Box>
        <Box className="container z3">
          <Box className="row">
            <Box className="col-md-6">
              <Box className="col-md-10">
                <img className="imgresponsive" src={cashbackImg} />
              </Box>
            </Box>

            <Box className="col-md-6">
              <Typography className="subtitle1" variant="h5">
                {" "}
                {translate("main.feelyour")}
              </Typography>
              <hr className="hr1"></hr>
              <Typography className="maintitle ">
                {" "}
                {translate("main.JoinUs")}{" "}
                <span>{translate("main.andgetunlimitedcashback")}</span>
              </Typography>
              <Typography>{translate("main.generatecashback")}</Typography>
              <hr className="hr1"></hr>

              <List className="introUl mt-2">
                <ListItem> {translate("main.Withuss")}</ListItem>
                <ListItem> {translate("main.Shopat")}</ListItem>
                <ListItem> {translate("main.Orderyourfood")}</ListItem>
                <ListItem> {translate("main.Payoff")}</ListItem>
              </List>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* ================================================= */}
      <Box className="footer">
        <Box className="container">
          <Typography>
            imPrint :{" "}
            <a
              style={{ textDecoration: "none", color: "white" }}
              href="https://www.sparissimo.com/impressum"
            >
              https://www.sparissimo.com/impressum
            </a>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
