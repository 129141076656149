import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";

const LANGS = [
  {
    label: "English",
    value: "en",
    icon: "/assets/icons/flags/ic_flag_en.svg",
  },

  {
    label: "German",
    value: "de",
    icon: "/assets/icons/flags/ic_flag_de.svg",
  },
  {
    label: "French",
    value: "fr",
    icon: "/assets/icons/flags/ic_flag_fr.svg",
  },
];

export const DEFAULT_LANGUAGE = LANGS[0];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const [currentLang, setCurrentLang] = useState(() => {
    const langStorage = localStorage.getItem("i18nextLng");
    if (langStorage) {
      const selectedLanguage = LANGS.find(
        (_lang) => _lang.value === langStorage
      );
      if (selectedLanguage) {
        i18n.changeLanguage(langStorage);
        return selectedLanguage;
      }
    }
    return DEFAULT_LANGUAGE;
  });

  const handleChangeLanguage = (newlang) => {
    i18n.changeLanguage(newlang);
  };

  useEffect(() => {
    localStorage.setItem("i18nextLng", i18n.language);
    const selectedLanguage = LANGS.find(
      (_lang) => _lang.value === i18n.language
    );
    setCurrentLang(selectedLanguage);
  }, [i18n.language]);
  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
}
