import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { PATH_AFTER_LOGIN } from "src/config";
import AuthGuard, { AdminGuard } from "src/guards/AuthGuard";
import DashboardLayout from "src/layouts/dashboard";
import Loadable from "../Loadable";
import communication from "./communication";
import eCommerce from "./eCommerce";
import invoice from "./invoice";
import store from "./store";
import user from "./user";
// DASHBOARD

// GENERAL
const GeneralApp = Loadable(
  lazy(() => import("src/pages/dashboard/GeneralApp"))
);
// NETWORK
const NetworkApp = Loadable(lazy(() => import("src/pages/dashboard/network")));
const GeneralEcommerce = Loadable(
  lazy(() => import("src/pages/dashboard/GeneralEcommerce"))
);
const GeneralAnalytics = Loadable(
  lazy(() => import("src/pages/dashboard/GeneralAnalytics"))
);
const GeneralBanking = Loadable(
  lazy(() => import("src/pages/dashboard/GeneralBanking"))
);
const GeneralBooking = Loadable(
  lazy(() => import("src/pages/dashboard/GeneralBooking"))
);

// BINARY
const Binary = Loadable(lazy(() => import("src/pages/genealogy/binary/index")));
const Sponsor = Loadable(
  lazy(() => import("src/pages/genealogy/bronzeTree/index"))
);
const Tree = Loadable(lazy(() => import("src/pages/genealogy/tree/index")));

// FINANCIAL
const Ewallet = Loadable(lazy(() => import("src/pages/financial/ewallet")));
const DepositWallet = Loadable(
  lazy(() => import("src/pages/financial/deposit"))
);
const FundCredits = Loadable(
  lazy(() => import("src/pages/financial/fundCredit"))
);
const Payout = Loadable(
  lazy(() => import("src/pages/financial/payout/payout"))
);
const FinancialReport = Loadable(
  lazy(() => import("src/pages/financial/financialReport/financialReport"))
);

// TOOLS
const Documents = Loadable(
  lazy(() => import("src/pages/tools/documents/index"))
);
const Videos = Loadable(lazy(() => import("src/pages/tools/videos")));
const Notice = Loadable(lazy(() => import("src/pages/tools/notice/index")));

//RESTAURANTS
const RestaurantSummary = Loadable(
  lazy(() => import("src/pages/restaurants/restaurantsummary"))
);
const PaymentHistory = Loadable(
  lazy(() => import("src/pages/restaurants/paymenthistory"))
);
const BankDetails = Loadable(
  lazy(() => import("src/pages/restaurants/bankdetails"))
);
const RestaurantOrders = Loadable(
  lazy(() => import("src/pages/restaurants/restaurantorders"))
);
const PayoutHistory = Loadable(
  lazy(() =>
    import(
      "src/pages/restaurants/restaurantsummary/components/restaurantSummary/payoutdetails"
    )
  )
);
const InvoiceHistory = Loadable(
  lazy(() =>
    import(
      "src/pages/restaurants/restaurantsummary/components/restaurantSummary/invoicedetails"
    )
  )
);
const ViewMore = Loadable(
  lazy(() =>
    import(
      "src/pages/restaurants/restaurantsummary/components/restaurantSummary/viewmore"
    )
  )
);
// MEMBERS MANAGEMENT
const NetworkMembers = Loadable(
  lazy(() => import("src/pages/members/network"))
);

const MemberProfile = Loadable(lazy(() => import("src/pages/members/Profile")));
const BankAccount = Loadable(
  lazy(() => import("src/pages/members/bankAccount/index.js"))
);
const KycApprove = Loadable(
  lazy(() => import("src/pages/members/viewKyc/index.js"))
);
// SUBSCRIPTIONS
const AddProduct = Loadable(
  lazy(() => import("src/pages/subscriptions/addproduct"))
);
const AddBusiness = Loadable(
  lazy(() => import("src/pages/subscriptions/addBusiness"))
);

const SubAdmin = Loadable(lazy(() => import("src/pages/subadmin/index")));
const AddSubAdminGroup = Loadable(
  lazy(() => import("src/pages/subadmin/groups/index"))
);
const SubAdminProfile = Loadable(
  lazy(() => import("src/pages/subadmin/Profile"))
);
const AddSubAdmin = Loadable(
  lazy(() => import("src/pages/subadmin/subadmins"))
);

// HOLDING-TANK
const HoldingTank = Loadable(
  lazy(() => import("src/pages/members/holdingTank/index"))
);

// SETTINGS
const BrandSettings = Loadable(lazy(() => import("src/pages/settings/brand")));
const BusinessSettings = Loadable(
  lazy(() => import("src/pages/settings/business/index"))
);
const Network = Loadable(
  lazy(() => import("src/pages/settings/network/index"))
);
const ControlPanel = Loadable(
  lazy(() => import("src/pages/settings/controlpanel"))
);

const Withdrawal = Loadable(
  lazy(() => import("src/pages/settings/withdrawal/index"))
);

const Branding = Loadable(
  lazy(() =>
    import("src/pages/settings/controlpanel/components/systems/branding")
  )
);
const PackageManager = Loadable(
  lazy(() =>
    import("src/pages/settings/controlpanel/components/network/Packagemanager")
  )
);

const PayoutManager = Loadable(
  lazy(() =>
    import("src/pages/settings/controlpanel/components/network/payout")
  )
);

const EcommerceManager = Loadable(
  lazy(() =>
    import("src/pages/settings/controlpanel/components/addons/e-commerce")
  )
);

const EmailManager = Loadable(
  lazy(() => import("src/pages/settings/controlpanel/components/addons/email"))
);

// REPORTS

const Builder = Loadable(lazy(() => import("src/pages/reports/builder")));
// old
const Joining = Loadable(lazy(() => import("src/pages/reports/joining")));
const FundCredit = Loadable(lazy(() => import("src/pages/reports/fundCredit")));
const MemberIcome = Loadable(
  lazy(() => import("src/pages/reports/member/index"))
);
const TopEarners = Loadable(
  lazy(() => import("src/pages/reports/topEarners/index"))
);
const PayoutReport = Loadable(
  lazy(() => import("src/pages/reports/payout/index"))
);
const SalesReport = Loadable(
  lazy(() => import("src/pages/reports/sales/index"))
);

const BuilderSubscription = Loadable(
  lazy(() => import("src/pages/reports/builderSubscription"))
);
const PointHistory = Loadable(
  lazy(() => import("src/pages/reports/pointHistory"))
);

const UserSubscriptions = Loadable(
  lazy(() => import("src/pages/userSubscriptions/index"))
);
const Statistics = Loadable(lazy(() => import("src/pages/statistics")));
const SubscriptionsUsers = Loadable(
  lazy(() => import("src/pages/statistics/components/pages/index"))
);
const ProfileUser = Loadable(
  lazy(() => import("src/pages/statistics/components/pages/profileUser"))
);
const OrdersRestaurants = Loadable(
  lazy(() => import("src/pages/restaurants/ordersRestaurants"))
);
const SponsorPurchase = Loadable(
  lazy(() => import("src/pages/members/sponsorPackage/index"))
);

const dashboard = {
  path: "dashboard",
  element: (
    <AuthGuard>
      <AdminGuard>
        <DashboardLayout />
      </AdminGuard>
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
    {
      path: "app",
      children: [
        { index: true, element: <Navigate to="business" /> },
        {
          path: "business",
          element: <GeneralApp />,
        },
        {
          path: "network",
          element: <NetworkApp />,
        },
      ],
    },
    { path: "ecommerce", element: <GeneralEcommerce /> },
    { path: "analytics", element: <GeneralAnalytics /> },
    { path: "banking", element: <GeneralBanking /> },
    { path: "booking", element: <GeneralBooking /> },
    { ...eCommerce },
    { ...user },
    { ...invoice },
    { ...communication },
    { ...store },
    {
      path: "genealogy",
      children: [
        { element: <Binary />, index: true },
        { path: "binary", element: <Binary /> },
        { path: "sponsor", element: <Sponsor /> },
        { path: "tree", element: <Tree /> },
      ],
    },
    {
      path: "financial",
      children: [
        { element: <Ewallet />, index: true },
        { path: "e-wallet", element: <Ewallet /> },
        { path: "deposit-wallet", element: <DepositWallet /> },
        { path: "fund-credits", element: <FundCredits /> },
        { path: "payout", element: <Payout /> },
        { path: "report", element: <FinancialReport /> },
      ],
    },

    {
      path: "tools",
      children: [
        { element: <Documents />, index: true },
        { path: "documents", element: <Documents /> },
        { path: "videos", element: <Videos /> },
        { path: "notices", element: <Notice /> },
      ],
    },
    {
      path: "restaurants",
      children: [
        { element: <RestaurantSummary />, index: true },
        { path: "restaurantsummary", element: <RestaurantSummary /> },
        { path: "paymenthistory", element: <PaymentHistory /> },
        { path: "bankdetails", element: <BankDetails /> },
        { path: "restaurantorders", element: <RestaurantOrders /> },
        { path: "payouthistory/:mid", element: <PayoutHistory /> },
        { path: "invoicehistory/:mid", element: <InvoiceHistory /> },
        { path: "viewmore", element: <ViewMore /> },
        { path: "ordersrestaurants", element: <OrdersRestaurants /> },
      ],
    },
    {
      path: "members",
      children: [
        { element: <Navigate to="network" />, index: true },
        { path: "network", element: <NetworkMembers /> },
        {
          path: "holding-tank",
          element: <HoldingTank />,
        },
        { path: "profile/:mid", element: <MemberProfile /> },
        { path: "bankaccount", element: <BankAccount /> },
        { path: "approvekyc", element: <KycApprove /> },
        {
          path: "sponsor-package-purchase",
          element: <SponsorPurchase />,
        },
      ],
    },
    {
      path: "subscriptions",
      children: [
        { element: <NetworkMembers />, index: true },
        { path: "add_product", element: <AddProduct /> },
        { path: "add_business", element: <AddBusiness /> },
      ],
    },
    {
      path: "sub-admin",
      children: [
        { index: true, element: <SubAdmin /> },

        { path: ":sid", element: <SubAdminProfile /> },
        { path: "add", element: <AddSubAdmin /> },
        {
          path: "group",
          children: [
            { index: true, element: <Navigate to="add" /> },
            { path: "add", element: <AddSubAdminGroup /> },
          ],
        },
      ],
    },

    {
      path: "settings",
      children: [
        { element: <Navigate to="brand_settings" />, index: true },
        { path: "brand_settings", element: <BrandSettings /> },
        { path: "business_settings", element: <BusinessSettings /> },
        { path: "network", element: <Network /> },
        { path: "control_panel", element: <ControlPanel /> },
        { path: "branding", element: <Branding /> },
        { path: "package_manager", element: <PackageManager /> },
        { path: "payout_manager", element: <PayoutManager /> },
        { path: "ecommerce_manager", element: <EcommerceManager /> },
        { path: "email_manager", element: <EmailManager /> },
        { path: "withdrawal", element: <Withdrawal /> },
      ],
    },
    {
      path: "report",
      children: [
        { element: <Builder />, index: true },
        { path: "builder", element: <Builder /> },
        { path: "fund_credit", element: <Builder /> },
        // old
        { path: "joining", element: <Joining /> },
        { path: "fund_credit", element: <FundCredit /> },
        { path: "member_icome", element: <MemberIcome /> },
        { path: "top_earners", element: <TopEarners /> },
        { path: "payout_report", element: <PayoutReport /> },
        { path: "sales_report", element: <SalesReport /> },

        {
          path: "builder_subscription",
          element: <BuilderSubscription />,
        },
        { path: "point_history", element: <PointHistory /> },
      ],
    },

    {
      path: "subscriptions",
      children: [
        { index: true, element: <UserSubscriptions /> },
        { path: "user", element: <UserSubscriptions /> },
      ],
    },
    {
      path: "statistics",
      children: [
        { index: true, element: <Statistics /> },
        {
          path: "subscriptions_users",
          element: <SubscriptionsUsers />,
        },
        { path: "profile_user", element: <ProfileUser /> },
      ],
    },
  ],
};

export default dashboard;
