import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { capitalCase } from "change-case";
import DataHandlerTable from "src/components/data-handler/table";
import Loop from "src/components/loop";
import PaginationButtons from "src/components/pagination";
import Scrollbar from "src/components/Scrollbar";
import useLocales from "src/hooks/useLocales";

const DataList = ({ state, rowStart, currency, ...rest }) => {
  const { translate } = useLocales();
  const { data, ...dataProps } = state;
  const headers = [
    "fundtransfer.no",
    "fundtransfer.username",
    "fundtransfer.amount",
    "invoiceDetails.paymentType",
    "fundtransfer.note",
    "fundtransfer.date",
  ];

  return (
    <>
      <Card sx={{ mt: 2 }}>
        <Scrollbar>
          <DataHandlerTable headers={headers} dataProps={dataProps}>
            <Loop
              list={data}
              render={(item, i) => {
                return (
                  <>
                    {" "}
                    <TableRow>
                      <TableCell>{i + rowStart}</TableCell>
                      <TableCell>{item.from_user?.username}</TableCell>
                      <TableCell>{item.total_amount}</TableCell>
                      <TableCell> {capitalCase(item.payment_type)}</TableCell>
                      <TableCell>{item.note ? item.note : "-"}</TableCell>

                      <TableCell>
                        {new Date(item.created_at)?.toLocaleDateString("en-GB")}
                      </TableCell>
                    </TableRow>
                  </>
                );
              }}
            />
          </DataHandlerTable>
        </Scrollbar>
      </Card>

      <PaginationButtons {...rest} />
    </>
  );
};

export default DataList;
