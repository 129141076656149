import { Grid } from "@mui/material";
import useLocales from "src/hooks/useLocales";
import Cards from "./cards";

const Summary = ({ summary, currency }) => {
    const { translate } = useLocales();

    const summaryCard = [
        {
            title: translate("balance"),
            icon: "icon-park-solid:balance-two",
            color: "#d279a6",
            key: "balance",
        },
        {
            title: translate("myEwallet.transferOut"),
            icon: "bx:transfer-alt",
            color: "#6699ff",
            key: "trasfer_out",
        },
        {
            title: translate("transferIn"),
            icon: "tabler:transfer-in",
            color: "#1c9115",
            key: "trasfer_in",
        },
        {
            title: translate("myEwallet.totalPayout"),
            icon: "fluent:wallet-48-regular",
            color: "#993366",
            key: "total_payout",
        },
        {
            title: translate("myEwallet.totalEarnedBonus"),
            icon: "carbon:ibm-cloud-pak-network-automation",
            color: "#999966",
            key: "total_earned",
        },
        // {
        //     title: translate("myEwallet.totalCashbackPurchase"),
        //     icon: "teenyicons:money-solid",
        //     color: "#d279a6",
        //     key: "total_cashback_purchase",
        //     tooltip: "myEwallet.tooltip.cashback",
        // },
    ];

    return (
        <>
            {summaryCard.map((props) => {
                return (
                    <Grid item xs={12} md={2.3}>
                        <Cards
                            {...props}
                            total={summary[props.key]}
                            currency={currency}
                        />
                    </Grid>
                );
            })}
        </>
    );
};

// const summaryCard = [
//   {
//     title: translate("myEwallet.balance"),
//     icon: "arcticons:priceconverter",
//     color: "#d279a6",
//     key: "balance",
//   },
//   {
//     title: "Transfer Out",
//     icon: "bx:transfer-alt",
//     color: "#6699ff",
//     key: "trasfer_out",
//   },
//   {
//     title: "Total Payout",
//     icon: "fluent:wallet-48-regular",
//     color: "#993366",
//     key: "total_payout",
//   },
//   {
//     title: "Total Earned Bonus",
//     icon: "carbon:ibm-cloud-pak-network-automation",
//     color: "#999966",
//     key: "total_earned",
//   },
// ];

export default Summary;
