import Label from "src/components/Label";
import SvgIconStyle from "src/components/SvgIconStyle";
import useLocales from "src/hooks/useLocales";
import { PATH_DASHBOARD } from "src/routes/paths";

const getIcon = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  booking: getIcon("ic_booking"),
  invoice: getIcon("ic_invoice"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  settings: getIcon("ic_settings"),
  tools: getIcon("ic_tools"),
  member_management: getIcon("ic_member_management"),
  subadmin: getIcon("ic_subadmin"),
  holdingtank: getIcon("ic_holdingtank"),
  store: getIcon("ic_store"),
  user_subscription: getIcon("ic_user_subscription"),
  report: getIcon("ic_report"),
  restaurants: getIcon("ic_restaurants"),
};

const useNavConfig = () => {
  const { translate } = useLocales();

  return [
    {
      items: [
        // {
        //   title: "Dashboard",
        //   path: PATH_DASHBOARD.general.app,
        //   icon: ICONS.shibi,
        // },
        {
          title: translate("dashboard"),
          path: PATH_DASHBOARD.dashboard.root,
          icon: ICONS.dashboard,
          children: [
            {
              title: translate("bussiness"),
              path: PATH_DASHBOARD.dashboard.business,
            },
            {
              title: translate("network"),
              path: PATH_DASHBOARD.dashboard.network,
            },
          ],
        },

        {
          title: "Genealogy",
          path: PATH_DASHBOARD.genealogy.root,
          icon: ICONS.kanban,
          children: [
            // { title: "Binary", path: PATH_DASHBOARD.genealogy.binary },
            {
              title: "Sponsor",
              path: PATH_DASHBOARD.genealogy.sponsor,
            },
            { title: "Tree", path: PATH_DASHBOARD.genealogy.tree },
          ],
        },
        {
          title: "Financial",
          path: PATH_DASHBOARD.financial.root,
          icon: ICONS.ecommerce,

          children: [
            {
              title: "Ewallet",
              path: PATH_DASHBOARD.financial.ewallet,
            },
            // {
            //   title: "Deposit Wallet",
            //   path: PATH_DASHBOARD.financial.deposit_wallet,
            // },
            {
              title: "Fund Credits",
              path: PATH_DASHBOARD.financial.fund_credits,
            },
            {
              title: "Payout",
              path: PATH_DASHBOARD.financial.payout,
            },
            // {
            //   title: "Financial Report",
            //   path: PATH_DASHBOARD.financial.financial_report,
            // },
          ],
        },
        {
          title: "Communication",
          path: PATH_DASHBOARD.communication.root,
          icon: ICONS.chat,
          children: [
            {
              title: "Blog",
              path: PATH_DASHBOARD.communication.blog,
            },
            {
              title: "FAQs",
              path: PATH_DASHBOARD.communication.com_faqs,
            },
            // { title: "Emails", path: PATH_DASHBOARD.communication.mails },
            {
              title: "Help Center",
              path: PATH_DASHBOARD.communication.help_center,
            },

            // {
            //   title: "Support Tickets",
            //   children: [
            //     {
            //       title: "Department",
            //       path: PATH_DASHBOARD.communication.com_support_department,
            //     },
            //     {
            //       title: "Categories",
            //       path: PATH_DASHBOARD.communication.com_support_categories,
            //     },
            //     {
            //       title: "Priorites",
            //       path: PATH_DASHBOARD.communication.com_support_priorities,
            //     },
            //     {
            //       title: "Canned Responses",
            //       path: PATH_DASHBOARD.communication.com_support_canned_response,
            //     },
            //     {
            //       title: "Tickets",
            //       path: PATH_DASHBOARD.communication.com_support_tickets,
            //     },
            //   ],
            // },
            // { title: "Article", path: PATH_DASHBOARD.communication.com_article },
          ],
        },

        {
          title: "Tools",
          path: PATH_DASHBOARD.tools.root,
          icon: ICONS.tools,
          children: [
            // { title: 'Faqs', path: PATH_DASHBOARD.tools.faqs },
            {
              title: "Documents",
              path: PATH_DASHBOARD.tools.documents,
            },
            { title: "Videos", path: PATH_DASHBOARD.tools.videos },
            { title: "Notice", path: PATH_DASHBOARD.tools.notice },
          ],
        },

        {
          title: "Restaurants",
          path: PATH_DASHBOARD.restaurants.root,
          icon: ICONS.restaurants,
          children: [
            {
              title: "Summary",
              path: PATH_DASHBOARD.restaurants.restaurantsummary,
            },
            {
              title: "Payment History",
              path: PATH_DASHBOARD.restaurants.paymenthistory,
            },
            {
              title: "Bank Accounts",
              path: PATH_DASHBOARD.restaurants.bankdetails,
            },
            {
              title: "Orders",
              path: PATH_DASHBOARD.restaurants.ordersrestaurants,
            },
          ],
        },
        {
          title: "Members Management",
          path: PATH_DASHBOARD.members.root,
          icon: ICONS.member_management,
          children: [
            {
              title: "Network Members",
              path: PATH_DASHBOARD.members.network,
            },
            {
              title: "Bank Accounts",
              path: PATH_DASHBOARD.members.bankaccount,
            },
            {
              title: "KYC",
              path: PATH_DASHBOARD.members.approvekyc,
            },
            {
              title: "Holding Tank",
              path: PATH_DASHBOARD.members.holdingTank,
            },
            // {
            //   title: "Sponsor Package Purchase",
            //   path: PATH_DASHBOARD.members.sponsorPurchase,
            // },
          ],
        },
        // {
        //   title: 'Assign Subscriptions',
        //   path: PATH_DASHBOARD.subscriptions.root,
        //   icon: ICONS.blog,
        //   children: [
        //     { title: 'Add Product', path: PATH_DASHBOARD.subscriptions.add_product },
        //     { title: 'Add business Builder Fee', path: PATH_DASHBOARD.subscriptions.add_business },
        //   ],
        // },
        // {
        //   title: "Sub-Admin",
        //   path: PATH_DASHBOARD.subAdmin.root,
        //   icon: ICONS.subadmin,
        //   children: [
        //     { title: "Sub Admins", path: PATH_DASHBOARD.subAdmin.sub_admins },
        //   ],
        // },
        // {
        //   title: "Holding-Tank",
        //   path: PATH_DASHBOARD.holdingTank.root,
        //   icon: ICONS.holdingtank,
        //   info: (
        //     <Label variant="outlined" color="warning">
        //       0
        //     </Label>
        //   ),
        // },
        // {
        //   title: "Store",
        //   path: PATH_DASHBOARD.store.root,
        //   icon: ICONS.store,
        //   children: [
        //     { title: "Products", path: PATH_DASHBOARD.store.products },
        //     { title: "Material", path: PATH_DASHBOARD.store.material },
        //     { title: "Events", path: PATH_DASHBOARD.store.events },
        //     { title: "Coupons", path: PATH_DASHBOARD.store.coupons },
        //     { title: "User Reviews", path: PATH_DASHBOARD.store.user_reviews },
        //     // {
        //     //   title: "Assign Subscriptions Categories",
        //     //   path: PATH_DASHBOARD.store.assign_subscriptions_cat,
        //     // },
        //     {
        //       title: "Assign Subscriptions",
        //       path: PATH_DASHBOARD.store.assign_subscriptions,
        //     },
        //     {
        //       title: "Business Builder Subscriptions sales",
        //       path: PATH_DASHBOARD.store.business_builder_subscriptions,
        //     },
        //   ],
        // },

        // {
        //   title: "User Subscriptions",
        //   icon: ICONS.user_subscription,
        //   path: PATH_DASHBOARD.activeSubscriptions.root,
        // },
        // {
        //   title: "Statistics",
        //   icon: ICONS.analytics,
        //   path: PATH_DASHBOARD.statistics.root,
        // },
      ],
    },

    {
      subheader: "GENERAL",
      items: [
        {
          title: "Settings",
          path: PATH_DASHBOARD.settings.root,
          icon: ICONS.settings,
          children: [
            // { title: 'Network', path: PATH_DASHBOARD.settings.network },

            {
              title: "Brand",
              path: PATH_DASHBOARD.settings.brand_settings,
            },
            // {
            //   title: "Business Builder",
            //   path: PATH_DASHBOARD.settings.business_settings,
            // },
            // {
            //   title: "Control Panel",
            //   path: PATH_DASHBOARD.settings.control_panel,
            // },
            {
              title: "Package Manager",
              path: PATH_DASHBOARD.settings.package_manager,
            },

            { title: "Withdrawal", path: PATH_DASHBOARD.settings.withdrawal },
            // { title: 'Business Builder Subscription', path: PATH_DASHBOARD.settings.business_builder },
            // { title: 'App Settings', path: PATH_DASHBOARD.settings.app_settings },
            // { title: 'Email Settings', path: PATH_DASHBOARD.settings.email_settings },
            // { title: 'Terms & Conditions', path: PATH_DASHBOARD.settings.terms_conditions },
            // { title: 'User Guidance', path: PATH_DASHBOARD.settings.user_guidance },
            // { title: 'Get Started', path: PATH_DASHBOARD.settings.get_started },
          ],
        },
        {
          title: "Reports",
          path: PATH_DASHBOARD.report.root,
          icon: ICONS.report,
          children: [
            // {
            //   title: "Builder Subscription",
            //   path: PATH_DASHBOARD.report.builder,
            // },
            // { title: "Fund Credit", path: PATH_DASHBOARD.report.fund_credit },
            {
              title: "Member Income",
              path: PATH_DASHBOARD.report.member_icome,
            },
            {
              title: "Top Earners",
              path: PATH_DASHBOARD.report.top_earners,
            },
            {
              title: "Payout",
              path: PATH_DASHBOARD.report.payout_report,
            },
            {
              title: "Sales",
              path: PATH_DASHBOARD.report.sales_report,
            },

            // {
            //   title: "Builder Subscription",
            //   path: PATH_DASHBOARD.report.builder_subscription,
            // },
            // { title: "Point History", path: PATH_DASHBOARD.report.point_history },
          ],
        },
      ],
    },

    // // MANAGEMENT
    // // ----------------------------------------------------------------------
    // {
    //   subheader: 'USED AREA',
    //   items: [

    //     {
    //       title: 'user',
    //       path: PATH_DASHBOARD.user.root,
    //       icon: ICONS.user,
    //       children: [
    //         { title: 'profile', path: PATH_DASHBOARD.user.profile },
    //         { title: 'cards', path: PATH_DASHBOARD.user.cards },
    //         { title: 'list', path: PATH_DASHBOARD.user.list },
    //         { title: 'create', path: PATH_DASHBOARD.user.new },
    //         { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
    //         { title: 'account', path: PATH_DASHBOARD.user.account },
    //       ],
    //     },

    //     // E-COMMERCE
    //     {
    //       title: 'e-commerce',
    //       path: PATH_DASHBOARD.eCommerce.root,
    //       icon: ICONS.cart,
    //       children: [
    //         { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
    //         { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
    //         { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
    //         { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
    //         { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
    //         { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
    //       ],
    //     },

    //     // INVOICE
    //     {
    //       title: 'invoice',
    //       path: PATH_DASHBOARD.invoice.root,
    //       icon: ICONS.invoice,
    //       children: [
    //         { title: 'list', path: PATH_DASHBOARD.invoice.list },
    //         { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
    //         { title: 'create', path: PATH_DASHBOARD.invoice.new },
    //         { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
    //       ],
    //     },

    //     // BLOG
    //     {
    //       title: 'blog',
    //       path: PATH_DASHBOARD.blog.root,
    //       icon: ICONS.blog,
    //       children: [
    //         { title: 'posts', path: PATH_DASHBOARD.blog.posts },
    //         { title: 'post', path: PATH_DASHBOARD.blog.demoView },
    //         { title: 'create', path: PATH_DASHBOARD.blog.new },
    //       ],
    //     },
    //   ],
    // },

    // // APP
    // // ----------------------------------------------------------------------
    // {
    //   subheader: 'app',
    //   items: [
    //     {
    //       title: 'mail',
    //       path: PATH_DASHBOARD.mail.root,
    //       icon: ICONS.mail,
    //       info: (
    //         <Label variant="outlined" color="error">
    //           +32
    //         </Label>
    //       ),
    //     },
    //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
    //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
    //     { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
    //   ],
    // },
  ];
};

export default useNavConfig;
