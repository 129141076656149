import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "../Loadable";
import { HideMenus } from "src/guards/AuthGuard";

const BinaryTree = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/binary/index"))
);

const BinaryLeg = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/binaryLeg/index"))
);

const Sponsor = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/bronzeTree/index"))
);

const Affiliate = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/affiliate/index"))
);

const Tree = Loadable(lazy(() => import("src/pages/userSide/genealogy/tree")));

const genealogy = {
  path: "genealogy",
  children: [
    {
      index: true,
      element: (
        <HideMenus>
          <Navigate to="binary" />
        </HideMenus>
      ),
    },
    { path: "binaryLeg", element: <BinaryLeg /> },
    { path: "binary", element: <BinaryTree /> },
    {
      path: "sponsor",
      element: (
        <HideMenus>
          <Sponsor />
        </HideMenus>
      ),
    },
    { path: "affiliate", element: <Affiliate /> },
    {
      path: "tree",
      element: (
        <HideMenus>
          <Tree />
        </HideMenus>
      ),
    },
  ],
};

export default genealogy;
