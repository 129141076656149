import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const defaultValues = {
  group_name: "",
  members: [],
  admin_id: null,
};

const schema = yup.object().shape({
  group_name: yup
    .string()
    .required("Group Name is required")
    .transform((v) => {
      return v.replaceAll("'", "").replaceAll('"', "");
    }),
  members: yup.array().min(1, "Select at least one user"),
});

const useGroupForm = () => {
  return useForm({ defaultValues, resolver: yupResolver(schema) });
};

export default useGroupForm;
