import { Box, Grid } from "@mui/material";
import { capitalCase } from "change-case";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import ExcelDownload from "src/components/ExcelDownload";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import useDataHandler from "src/components/data-handler/hooks/use-data-handler";
import usePagination from "src/components/pagination/usePagination";
import useAuth from "src/hooks/useAuth";
import useErrors from "src/hooks/useErrors";
import useLocales from "src/hooks/useLocales";
import useQueryParams from "src/hooks/useQueryParams";
import { PATH_USER } from "src/routes/paths";
import axiosInstance from "src/utils/axios";
import useFetchSummary from "../hooks/useFetchSummary";
import useFetchWitPagination from "../hooks/useFetchWithPagination";
import { DataList, FilterCard } from "./components";
import Summary from "./components/summary";

const useHistory = () => {
    const [dataLoading, setDataLoading] = useState(false);
    const [querys, setQuerys] = useState(null);
    const [data, setData] = useState([]);
    const location = useLocation();
    const { queryObject } = useQueryParams();
    const { deleteParam } = useQueryParams();
    const { packages: query, newType: newOne } = queryObject;
    const handleErrors = useErrors();
    const [filter, setFilter] = useState({
        start_date: null,
        end_date: null,
    });
    const [state, actions] = useDataHandler();
    const { count, onChange, page, seed, rowStart } = usePagination();
    const fetchData = async (page = 1, filter = {}) => {
        actions.loading();
        try {
            const { status, data } = await axiosInstance.get(
                "api/user/ewallet",
                {
                    params: {
                        page,
                        ...filter,
                    },
                }
            );
            if (status) {
                setQuerys(query);
                setDataLoading(false);
                const {
                    data: eWallet,
                    data_unpaginated,
                    last_page,
                    from,
                } = data?.data;
                onChange(null, page);
                seed(last_page, from);
                if (Boolean(eWallet.length)) {
                    actions.success(eWallet);
                    return;
                }
            }
            actions.success();
        } catch (err) {
            actions.error();
            handleErrors(err);
        }
    };
    useEffect(() => {
        if (query) {
            fetchData(page, {
                payment_type: query,
            });
        } else {
            fetchData(page, filter);
        }
    }, [page]);

    return {
        newOne,
        setQuerys,
        querys,
        setDataLoading,
        dataLoading,
        state,
        fetchData,
        count,
        onChange,
        page,
        rowStart,
        setFilter,
        filter,
    };
};

const Index = () => {
    const {
        setDataLoading,
        dataLoading,
        state,
        fetchData,
        rowStart,
        setFilter,
        filter,
        querys,
        newOne,
        setQuerys,
        ...rest
    } = useHistory();
    const { user } = useAuth();
    const { summary } = useFetchSummary("ewallet/boxes");
    const { translate } = useLocales();

    console.log(summary);
    return (
        <div>
            <Page title="My E-wallet: Financial">
                <Box sx={{ p: 2 }}>
                    {newOne ? (
                        <HeaderBreadcrumbs
                            heading={translate("myEwallet.myEwallet")}
                            links={[
                                {
                                    name: translate("myEwallet.dashboard"),
                                    href: PATH_USER.root,
                                },
                                newOne
                                    ? newOne === "partner"
                                        ? {
                                              name: translate(
                                                  "MyPartnerPortal"
                                              ),
                                              href: PATH_USER.onlineStore
                                                  .partnerpackage,
                                          }
                                        : newOne === "sponsor"
                                        ? {
                                              name: translate(
                                                  "MySponsorPortal"
                                              ),
                                              href: PATH_USER.onlineStore
                                                  .sponserpackage,
                                          }
                                        : newOne === "spartoken"
                                        ? {
                                              name: translate(
                                                  "MySpartokenPortal"
                                              ),
                                              href: PATH_USER.spartoken
                                                  .packages,
                                          }
                                        : ""
                                    : "",
                                { name: translate("myEwallet.myEwallet") },
                            ]}
                        />
                    ) : (
                        <HeaderBreadcrumbs
                            heading={translate("myEwallet.myEwallet")}
                            links={[
                                {
                                    name: translate("myEwallet.dashboard"),
                                    href: PATH_USER.root,
                                },
                                querys
                                    ? querys === "partner"
                                        ? {
                                              name: translate(
                                                  "MyPartnerPortal"
                                              ),
                                              href: PATH_USER.onlineStore
                                                  .partnerpackage,
                                          }
                                        : querys === "sponsor"
                                        ? {
                                              name: translate(
                                                  "MySponsorPortal"
                                              ),
                                              href: PATH_USER.onlineStore
                                                  .sponserpackage,
                                          }
                                        : querys === "spartoken"
                                        ? {
                                              name: translate(
                                                  "MySpartokenPortal"
                                              ),
                                              href: PATH_USER.spartoken
                                                  .packages,
                                          }
                                        : ""
                                    : "",
                                { name: translate("myEwallet.myEwallet") },
                            ]}
                        />
                    )}

                    <Grid container spacing={3}>
                        <Summary summary={summary} currency={user.currency} />
                        <Grid item xs={12} md={12}>
                            <FilterCard
                                querys={querys}
                                setQuerys={setQuerys}
                                setDataLoading={setDataLoading}
                                dataLoading={dataLoading}
                                setFilter={setFilter}
                                filter={filter}
                                handleFilter={fetchData}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <DataList
                                currency={user.currency}
                                state={state}
                                rowStart={rowStart}
                                fetchData={fetchData}
                                {...rest}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Page>
        </div>
    );
};

export default Index;
