import useSettings from "src/hooks/useSettings";
import Horizontal from "../components/horizontal";
import Vertical from "../components/vertical";
// import navConfig from "./NavConfig";
import useNavConfig from "./NavConfig";

const UserLayout = () => {
  const { themeLayout } = useSettings();
  const navConfig = useNavConfig();

  const verticalLayout = themeLayout === "vertical";

  return verticalLayout ? (
    <Vertical navConfig={navConfig} />
  ) : (
    <Horizontal navConfig={navConfig} />
  );
};
export default UserLayout;
