import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import useAuth from "src/hooks/useAuth";
import useLocales from "src/hooks/useLocales";
import axiosInstance from "src/utils/axios";
import Iconify from "../Iconify";
import registerPopup from "./assets/register-popup.jpg";

const FirstLogin = () => {
    const { user } = useAuth();
    const firstLogin = user?.first_login || 0;
    const { translate } = useLocales();

    const [openDialog, setOpenDialog] = useState(false);

    const loadingComplete = Object.keys(user).length > 0;

    useEffect(() => {
        if (loadingComplete) {
            setOpenDialog(firstLogin === 0);
        }
    }, [firstLogin, loadingComplete]);
    const { enqueueSnackbar } = useSnackbar();

    const handleClose = async () => {
        try {
            await axiosInstance.get("api/user/register-info-close");
            setOpenDialog(false);
        } catch (err) {
            enqueueSnackbar(err.message, { variant: "error" });
            console.error(err);
        }
    };

    return (
        <Dialog open={openDialog} maxWidth="xs" fullWidth>
            <Box
                sx={{
                    objectFit: "contain",
                    position: "relative",
                }}
            >
                <img
                    src={registerPopup}
                    style={{
                        objectFit: "contain",
                    }}
                />
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        top: 5,
                        right: 5,
                    }}
                >
                    <Iconify
                        icon="ic:round-close"
                        sx={{
                            color: "#fff",
                        }}
                    />
                </IconButton>
            </Box>

            <DialogTitle sx={{ mb: 2 }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                        width: "100%",
                        textAlign: "center",
                    }}
                >
                    <Typography variant="h6" textAlign="center" width="100%">
                        {translate("first_login.title", {
                            username: user.username,
                        })}
                    </Typography>
                    {/* <IconButton size="small" onClick={handleClose}>
            <Iconify icon="ic:round-close" />
          </IconButton> */}
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" textAlign="center">
                    {translate("first_login.body")}
                </Typography>
            </DialogContent>
        </Dialog>
    );
};

export default FirstLogin;
