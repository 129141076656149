import { Box, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import useLocales from "src/hooks/useLocales";
import useResponsive from "src/hooks/useResponsive";
import LanguagePopover from "src/layouts/shared/header/LanguagePopover";
import { PATH_AUTH } from "src/routes/paths";

const GetStartedTitle = () => {
  const smUp = useResponsive("up", "sm");
  const { translate } = useLocales();
  return (
    smUp && (
      <>
        <Box sx={{ marginTop: "-25px", marginLeft: "43rem" }}>
          <LanguagePopover />
        </Box>
        <Typography variant="body2" sx={{ mt: { md: -2 } }}>
          {translate("login.dontHave")}&nbsp;? &nbsp;&nbsp;
          <a
            style={{
              color: "#ff9800",
              fontWeight: "bold",
              textDecoration: "none",
            }}
            href={process.env.REACT_APP_WORD_PRESS_REGISTER}
          >
            {translate("login.getStarted")}
          </a>
        </Typography>
      </>
    )
  );
};

export default GetStartedTitle;
