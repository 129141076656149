import React from "react";
// @mui
// import DatePicker from "@mui/lab/DatePicker";
import { DatePicker } from "@mui/x-date-pickers";
import { Box, Button, Card, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import useLocales from "src/hooks/useLocales";
// components
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";

const FilterCard = () => {
  const { translate } = useLocales();
  const methods = useForm();
  const onSubmit = methods.handleSubmit((inputData) => {});
  const {
    control,
    formState: { isSubmitting },
  } = methods;
  return (
    <>
      <Card sx={{ p: 3 }}>
        <Box>
          <Typography variant="subtitle2">
            {translate("depositwallet.walletHistory")}
          </Typography>

          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Box
              sx={{
                display: "grid",
                rowGap: 3,
                columnGap: 2,
                marginTop: 2,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(4, 1fr)",
                },
              }}
            >
              <Controller
                control={control}
                name="start_date"
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    label={translate("depositwallet.pickStartDate")}
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                control={control}
                name="end_date"
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    label={translate("depositwallet.pickendDate")}
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />

              <RHFTextField
                name="userName"
                label={translate("depositwallet.username")}
              />
              <RHFSelect
                name="amountType"
                label={translate("depositwallet.amountType")}
              >
                <option value="all">All</option>
                <option value="released">Released</option>
                <option value="pending">Pending</option>
                <option value="failed">Failed</option>
                <option value="rejected">Rejected</option>
                <option value="finished">Finished</option>
                <option value="approved">Approved</option>
                <option value="fund_transfer">Fund transfer</option>
                <option value="plan_purchase">Plan purchase</option>
                <option value="fund_transfer">Fund transfer</option>
                <option value="self_transfer">Self transfer</option>
                <option value="referral_bonus">Referral bonus</option>
                <option value="achievement_bonus">Achievement bonus</option>
                <option value="first_order_bonus">First order bonus</option>
                <option value="binary_bonus">Binary bonus</option>
                <option value="credited_by_admin">Credited by admin</option>
                <option value="deducted_by_admin">Deducted by admin</option>
              </RHFSelect>
            </Box>
            <Box mt={2} sx={{ float: "right" }}>
              <Button variant="contained">
                {translate("depositwallet.getReport")}
              </Button>
            </Box>
          </FormProvider>
        </Box>
      </Card>
    </>
  );
};

export default FilterCard;
